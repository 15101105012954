import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import SubDomainFooter from "components/Footer/SubDomainFooter";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, Navigation } from "swiper";
import {
  GetDomainsAndDetails,
  TopWinners,
  NftsUnloacked,
  CampaignUsers,
} from "../actions/v1/token";
import ReactHTMLParser from "react-html-parser";
import config from "lib/config";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { ThreeDots } from "react-loader-spinner";
import Button from "components/CustomButtons/Button.js";
import { WbSunny, Brightness3 } from "@material-ui/icons";


function NewSubDomain() {
  const cookies = new Cookies();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [Sateeee, SetStatewww] = useState("");
  const [winner, Setwinner] = useState([]);
  const [unlocaked, SetUnlocked] = useState();
  const [Cookeieeeee, SetCookeieeeee] = useState(null);
  const [UserDetails, SetUserDetails] = useState(null);
  const [theme1, settheme1] = useState(true);

  useEffect(() => {
    Backendcalll();
    
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
  }, [cookies.get("TokenIDSCookie")]);

  useEffect(() => {
    TokenTopWinner();
  }, [cookies.get("TokenIDSCookie")]);

  const Backendcalll = async () => {
    console.log("Loaction For Routes", window.location.hostname);
    const SubDomain = getSubDomain(window.location.hostname);
    console.log("SubDomain", SubDomain);
    if (SubDomain !== "") {
      var sendData = {
        domainname: SubDomain,
      };
      var calllssss = await GetDomainsAndDetails(sendData);
      console.log("jknjckdkaasas", calllssss);
      if (calllssss?.data?.Data == null) {
        //console.log("aasdsdadas", Apps);
        // return Apps[0].app
        SetStatewww([]);
      } else {
        
        //return Apps[1].app
        localStorage.setItem("Event", calllssss?.data?.Data.domainname);
        localStorage.setItem("Creator", calllssss?.data?.Data.curraddress);
        SetStatewww(calllssss?.data?.Data);
      }
    } else {
      SetStatewww([]);
    }
  };

  const getSubDomain = (location) => {
    const locationParts = location.split(".");
    let slicefill = -2;
    const isLocalhost = locationParts.slice(-1)[0] === "localhost";
    if (isLocalhost) {
      slicefill = -1;
    }
    console.log(
      "dajkmfjfa",
      isLocalhost,
      locationParts,
      locationParts.slice(0, slicefill).join("")
    );
    return locationParts.slice(0, slicefill).join("");
  };

  useEffect(() => {
    console.log("TokenIDSCookie", cookies.get("TokenIDSCookie"));
    localStorage.setItem("Ids", cookies.get("TokenIDSCookie"));

    localStorage.setItem("ADDR", cookies.get("ClaimerCookie"));
    ClaimedNfts(
      cookies.get("TokenIDSCookie"),
      cookies.get("ClaimerCookie"),
      Sateeee
    );
    GetConnectedUser(cookies.get("ClaimerCookie"));
  }, [cookies.get("TokenIDSCookie")]);

  const TokenTopWinner = async (Addr) => {
    var SendData = {
      EventCreator: localStorage.getItem("Creator"),
      DomainName: localStorage.getItem("Event"),
    };
    var Winner = await TopWinners(SendData);
    Setwinner(Winner?.data?.TopWinners);
  };

  const ClaimedNfts = async (IDS, Addr, Sate) => {
    if (Addr) { 
      var SendData = {
        claimer: localStorage.getItem("ADDR"),
        QR_tokenID: localStorage.getItem("Ids"),
        EventCreator: localStorage.getItem("Creator"),
        DomainName: localStorage.getItem("Event"),
      };
      var UnloackedNfts = await NftsUnloacked(SendData);
      SetUnlocked(UnloackedNfts?.data?.Data);
      SetCookeieeeee(IDS);
    }
  };

  const MatchFun = async (add, unlock) => {
    var filterData = await Sateeee?.NoOfNfts?.map((item, index) => {
      var matchData = add?.filter((it) => it.QR_tokenID == item.QR_tokenID);
    });
  };

  const GetConnectedUser = async (Addr) => {
    if (Addr) {
      var SendData = {
        claimer: Addr,
        // localStorage.getItem("ADDR"),
      };
      var UserDetail = await CampaignUsers(SendData);
      SetUserDetails(UserDetail?.data?.Data);
    }
  };

  useEffect(() => {
    let pointerFrom = 0;
    let elementFrom = 0;

    const scrollingballs = document.getElementById("scrolling_avatars");

    console.log(scrollingballs, "balls");
    const onDrag = (event) => {
      if (event.pointerType == "mouse") {
        console.log("draged");
        console.log(elementFrom - event.clientX + pointerFrom, "ecolleft");
        scrollingballs.scrollLeft = elementFrom - event.clientX + pointerFrom;
      }
    };

    scrollingballs.onpointerdown = (event) => {
      console.log("Pointer down event");
      console.log("pointeron");

      if (event.pointerType == "mouse") {
        let pointerDown = true;
        pointerFrom = event.clientX;
        elementFrom = scrollingballs.scrollLeft;
        document.addEventListener("pointermove", onDrag);
      }
    };


    document.addEventListener("pointerup", (event) => {
      if (event.pointerType == "mouse") {
        document.removeEventListener("pointermove", onDrag);
      }
    });
  }, []);

  useEffect(() => {
    console.log("asdsadfsaasdas", localStorage.getItem("theme1"));
    themechange(localStorage.getItem("theme1"));
  }, []);

  useEffect(() => {
    let pointerFrom = 0;
    let elementFrom = 0;

    const scrollingballs3 = document.getElementById("scrolling_mobile");

    const onDrag3 = (event) => {
      if (event.pointerType == "mouse") {
        console.log("draged");
        console.log(elementFrom - event.clientX + pointerFrom, "ecolleft");
        scrollingballs3.scrollLeft = elementFrom - event.clientX + pointerFrom;
      }
    };

    scrollingballs3.onpointerdown = (event) => {


      if (event.pointerType == "mouse") {
        let pointerDown = true;
        pointerFrom = event.clientX;
        elementFrom = scrollingballs3.scrollLeft;
        document.addEventListener("pointermove", onDrag3);
      }
    };

    document.addEventListener("pointerup", (event) => {
      if (event.pointerType == "mouse") {
        document.removeEventListener("pointermove", onDrag3);
      }
    });
  }, []);

  const themechange = (data) => {
    if (data == "DT" || data == undefined) {
      localStorage.setItem("theme1", "DT");
      document.getElementById("root").classList.remove("light_theme");
      document.getElementById("root").classList.add("dark_theme");
      settheme1(false);
    } else {
      localStorage.setItem("theme1", "LT");
      document.getElementById("root").classList.add("light_theme");
      document.getElementById("root").classList.remove("dark_theme");
      settheme1(true);
    }
  };

  return (
    <>
      {console.log("djfsdfmklsdf", Sateeee)}
      <div
        className="home_header home header_bar_align subdomain_des"
        // style={{ backgroundColor: Sateeee.backgroundcolor }}
      >
        <div className="container pt-4">
        <div className="text-right pt-3 mb-3">
             <a href="https://lazyminter.com/" target="_blank"> <button className="create_btn">Log In</button></a>
              </div>   
          <div className="d-flex justify-content-between allthemes align-items-center">
            <div className="mb-3">
              {Sateeee?.companylogo == "" ||
              Sateeee?.companylogo == null ||
              Sateeee?.companylogo == undefined ? (
                <img
                  className="subd_logo"
                  src={require("../assets/images/faces/logo.png")}
                />
              ) : (
                <img
                  className="subd_logo"
                  src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                />
              )}
            </div>

            <div>
              {localStorage.getItem("theme1") == "LT" ||
              localStorage.getItem("theme1") == undefined ? (
                <Button
                  onClick={() => {
                    themechange("DT");
                  }}
                >
                  <p className="light svg_lft_align">
                    <Brightness3 />
                  </p>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    themechange("LT");
                  }}
                >
                  <p className="dark svg_lft_align">
                    <WbSunny />
                  </p>
                </Button>
              )}
            </div>
          </div>

          <div className="bannerbgclr">
            <div className="row align-items-center">
              <div className="col-md-6 mt-3">
                {Sateeee?.bannerTittle == "" ||
                Sateeee?.bannerTittle == null ||
                Sateeee?.bannerTittle == undefined ? (
                  <h3 className="subd_title">
                    Collect Limited Editions of Halloween NFTs
                  </h3>
                ) : (
                  <h3 className="subd_title">{Sateeee?.bannerTittle}</h3>
                )}
                {Sateeee?.bannerdiscription == "" ||
                Sateeee?.bannerdiscription == null ||
                Sateeee?.bannerdiscription == undefined ? (
                  <p className="subd_hinttxt">
                    Immerse yourself in the spooky world of Universal Studio's
                    iconic Halloween events through a collection of exclusive
                    digital collectibles. Each NFT showcases spine-chilling
                    characters, haunted attractions, and memorable moments,
                    meticulously crafted to capture the essence of the Halloween
                    experience. But that's not all - these NFTs also unlock a
                    unique treasure hunt for NFT holders, with a chance to win
                    incredible prizes!
                  </p>
                ) : (
                  <p className="subd_hinttxt">
                    {ReactHTMLParser(Sateeee.bannerdiscription)}
                  </p>
                )}
                <div className="subd_bannerbtns">
                  <a href="/#flowing_tocard">
                    <button className="subd_orangebtn">Collect Now</button>
                  </a>
                  {/* <button className='subd_outlinebtn'>Get early Access</button> */}
                </div>
              </div>
              <div className="col-md-6 mt-3 backclr">
                {Sateeee?.bannerImage == "" ||
                Sateeee?.bannerImage == null ||
                Sateeee?.bannerImage == undefined ? (
                  <ThreeDots
                    height="70"
                    width="70"
                    radius="9"
                    color="#FFC700"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <img
                    className="img-fluid w-100"
                    src={`${config.Back_URL}/${Sateeee?.bannerImage}`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="subd_jointreasure_dtls">
            {Sateeee?.middlebannertittle == "" ||
            Sateeee?.middlebannertittle == null ||
            Sateeee?.middlebannertittle == undefined ? (
              <h4 className="subd_jointr_ttl">Join Us In The Treasure Hunt</h4>
            ) : (
              <h4 className="subd_jointr_ttl">{Sateeee?.middlebannertittle}</h4>
            )}
            {/* <h4 className='subd_jointr_ttl'>Collect Your NFT Tokens </h4> */}
            {Sateeee?.middlebannerdescription == "" ||
            Sateeee?.middlebannerdescription == null ||
            Sateeee?.middlebannerdescription == undefined ? (
              <p className="subd_jointr_hint">
                Exchange for a mystery prize once you had collected all 7 NFT
                tokens
              </p>
            ) : (
              <p className="subd_jointr_hint">
                {ReactHTMLParser(Sateeee.middlebannerdescription)}
              </p>
            )}
            <div className="voilet_blurdark jointr_right"></div>
            <div className="voilet_blurdark jointr_left"></div>

            <div className="container subd_nointrcontbg" style={{ backgroundColor: Sateeee.backgroundcolor }}>
              <div className="row">
                <div className="col-xxl-5 col-cl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                  {Sateeee?.middlebannerimage == "" ||
                  Sateeee?.middlebannerimage == null ||
                  Sateeee?.middlebannerimage == undefined ? (
                    // <img className='img-fluid subd_jointr_contimg' src={require('../assets/images/faces/image2.png')} />
                    <ThreeDots
                      height="70"
                      width="70"
                      radius="9"
                      color="#FFC700"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  ) : (
                    <img
                      className="img-fluid subd_jointr_contimg"
                      src={`${config.Back_URL}/${Sateeee?.middlebannerimage}`}
                    />
                  )}
                </div>
                <div className="col-xxl-7 col-cl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                  {Sateeee?.middlebannerdescriptionTittle == "" ||
                  Sateeee?.middlebannerdescriptionTittle == null ||
                  Sateeee?.middlebannerdescriptionTittle == undefined ? (
                    <h5 className="subd_jointr_cntttl">
                      Collectible Halloween Tokens Only At Universal Studios in
                      Sentosa
                    </h5>
                  ) : (
                    <h5 className="subd_jointr_cntttl">
                      {Sateeee.middlebannerdescriptionTittle}
                    </h5>
                  )}

                  {Sateeee?.middlebannerdetails == "" ||
                  Sateeee?.middlebannerdetails == null ||
                  Sateeee?.middlebannerdetails == undefined ? (
                    <p className="subd_jointr_cnthint">
                      We are a community based collection project featuring 3D
                      and NFT artwork. Cool 3D from me and comes in all kinds of
                      evil shape traits and sizes with a few surprises along the
                      way
                    </p>
                  ) : (
                    <p className="subd_jointr_cnthint">
                      {ReactHTMLParser(Sateeee?.middlebannerdetails)}
                    </p>
                  )}
                  <div className="subd_whole_avtgrp">
                   
                    <AvatarGroup
                      className="subd_avtgrp"
                      id="scrolling_avatars"
                      max={100}
                    >
                      {Sateeee?.NoOfNfts?.length < 0 ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={require("../assets/images/faces/pf.png")}
                        />
                      ) : (
                        <>
                          {Sateeee?.NoOfNfts?.length > 0 &&
                            Sateeee?.NoOfNfts?.map((item, index) => {
                              
                              return (
                                <>
                                  {unlocaked?.some(
                                    (it) => it.QR_tokenID == item.QR_tokenID
                                  ) == true ? (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                    />
                                  ) : (
                         
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={require("../assets/images/faces/pf.png")}
                                    />
                                  )}
                                </>
                              );
                            })}
                        </>
                      )}
           
                    </AvatarGroup>
                    {console.log("sdadsadasd", UserDetails)}
                    {UserDetails !== null ? (
                      <div className="subd_singleavttxt">
                        <div className="subd_single_avt">
                          {UserDetails?.image == "" ||
                          UserDetails?.image == null ||
                          UserDetails?.image == undefined ? (
                            <>
                              {Sateeee?.companylogo == "" ||
                              Sateeee?.companylogo == null ||
                              Sateeee?.companylogo == undefined ? (
                                <img
                                  src={require("../assets/images/faces/logo.png")}
                                />
                              ) : (
                                <img
                                  src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={`${config.Back_URL}/Users/${UserDetails.PrimaryAddr}/profileImg/${UserDetails?.image}`}
                            />
                          )}
                          {/* <img src={require('../assets/images/faces/logo.png')} /> */}
                        </div>
                        <div className="ml-3">
                          <p className="subd_universal">
                            {UserDetails.name == "" ||
                            UserDetails.name == undefined
                              ? localStorage.getItem("ADDR").slice(0, 10)
                              : UserDetails.name}
                          </p>
                          {/* <div className='subd_ethreum'>

                            <p className='subd_value m-0'>340,793</p>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="subd_singleavttxt">
                        <div className="subd_single_avt">
                          {Sateeee?.companylogo == "" ||
                          Sateeee?.companylogo == null ||
                          Sateeee?.companylogo == undefined ? (
                            <img
                              src={require("../assets/images/faces/logo.png")}
                            />
                          ) : (
                            <img
                              src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                            />
                          )}
                        </div>
                        <div className="ml-3">
                          {Sateeee?.campaignname == "" ||
                          Sateeee?.campaignname == null ||
                          Sateeee?.campaignname == undefined ? (
                            <p className="subd_universal">Tea Factory</p>
                          ) : (
                            <p className="subd_universal">
                              {Sateeee?.campaignname}
                            </p>
                          )}

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="subd_collectall">
            {Sateeee?.categoryTittle == "" ||
            Sateeee?.categoryTittle == null ||
            Sateeee?.categoryTittle == undefined ? (
              <h4 className="subd_jointr_ttl mb-5">
                Collect ALL 7 of Them and receive one mystery Prize
              </h4>
            ) : (
              <h5 className="subd_jointr_ttl mb-5">{Sateeee.categoryTittle}</h5>
            )}

            <div className="container subd_collectallcont">
              <div className="blue_blurdark clct_all"></div>
              {Sateeee?.NoOfNfts?.length > 0 ? (
                <div className="row">
                  {Sateeee.NoOfNfts.length > 0 &&
                    Sateeee.NoOfNfts.map((item, index) => {
                      {
                        console.log("sdvdfsvmsdknjfksdnf", item);
                      }
                      return (
                        <div
                          id="flowing_tocard"
                          className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
                        >
                          <div
                          style={{ backgroundColor: Sateeee.backgroundcolor }}
                            class="card sub_domaincard"
                            id={
                              unlocaked?.some(
                                (it) => it.QR_tokenID == item.QR_tokenID
                              ) == true
                                ? ""
                                : "blur_card"
                            }
                          >
                            <div className="blur_effect"></div>
                            <div className="blur_card_style"></div>
                            <div className="sub_domaincard_rel">
                  
                            </div>
                            {console.log(
                              "asdasdasdasdasdqwe",
                              unlocaked?.some(
                                (it) => it.QR_tokenID == item.QR_tokenID
                              )
                            )}
                            {item.image != "" ? (
                              <>
                                {item.image != "" &&
                                  (item.image.split(".").pop() == "mp4" ? (
                                    <video
                                      id="my-video"
                                      class="card-img-top subdomain_cardimg"
                                      autoPlay
                                      playsInline
                                      loop
                                      muted
                                      preload="auto"
                                      controls
                                      disablePictureInPicture
                                      controlsList="nodownload"
                                    >
                                      <source
                                        src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                        type="video/mp4"
                                        id={
                                          unlocaked?.some(
                                            (it) =>
                                              it.QR_tokenID == item.QR_tokenID
                                          ) == true
                                            ? ""
                                            : "blur_card"
                                        }
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                      alt="Collections"
                                      className="card-img-top subdomain_cardimg"
                                      id={
                                        unlocaked?.some(
                                          (it) =>
                                            it.QR_tokenID == item.QR_tokenID
                                        ) == true
                                          ? ""
                                          : "blur_card"
                                      }
                                    />
                                  ))}
                              </>
                            ) : (
                              <>
                                {/* <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" /> */}
                                <ThreeDots
                                  height="70"
                                  width="70"
                                  radius="9"
                                  color="#FFC700"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </>
                            )}

                            <div class="card-body">
                              <div className="subd_cardwhole">
                                <div>
                                  <h6 className="wekit_aligned">
                                    {item?.tokenName}
                                  </h6>
                                  {/* <p>{`@ ${item?.tokenCreatorInfo?.name ? item?.tokenCreatorInfo?.name : item?.tokenCreatorInfo?.curraddress?.slice(0, 7)}`}</p> */}
                                </div>
                                <div>
                                  {Sateeee?.companylogo == "" ||
                                  Sateeee?.companylogo == null ||
                                  Sateeee?.companylogo == undefined ? (
                                    <img
                                      className="subd_cardsublogo"
                                      src={require("../assets/images/faces/logo.png")}
                                    />
                                  ) : (
                                    <img
                                      className="subd_cardsublogo"
                                      src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                    />
                                  )}
                                </div>
                              </div>

                              <button className="subd_clctnowbtn">
                                {unlocaked?.some(
                                  (it) => it.QR_tokenID == item.QR_tokenID
                                ) == true
                                  ? "unlocked"
                                  : "locked"}
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <>
                  <ThreeDots
                    height="70"
                    width="70"
                    radius="9"
                    color="#FFC700"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />

                </>
              )}
       
            </div>
            <div className="container subd_topwinner">
              {Sateeee?.TopWinnerTittle == "" ||
              Sateeee?.TopWinnerTittle == null ||
              Sateeee?.TopWinnerTittle == undefined ? (
                <h4 className="subd_jointr_ttl">Top Winner this week </h4>
              ) : (
                <h4 className="subd_jointr_ttl">{Sateeee?.TopWinnerTittle} </h4>
              )}

              {console.log("sdfdfdafa", winner)}
              {winner && winner?.length > 0 ? (
                <div className="row subd_winnercardrow">
                  {winner &&
                    winner?.length > 0 &&
                    winner.map((Data, index) => {
                      return (
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                            <div class="card-body">
                              <div className="subd_winnercard_whole">
                                <div className="d-flex justify-content-start align-items-center">
                                  {Data?.image == "" ||
                                  Data?.image == null ||
                                  Data?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                      Sateeee?.companylogo == null ||
                                      Sateeee?.companylogo == undefined ? (
                                        <img
                                          className="subd_cardsublogoal"
                                          src={require("../assets/images/faces/logo.png")}
                                        />
                                      ) : (
                                        <img
                                          className="subd_logo"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="subd_logo"
                                      src={`${config.Back_URL}/Users/${Data?.PrimaryAddr}/profileImg/${Data?.image}`}
                                    />
                                  )}

                                  <div className="ml-3">
                                    <p className="subd_universal">
                                      {Data?.name == ""
                                        ? Data.PrimaryAddr?.slice(0, 20)
                                        : Data?.name?.slice(0, 20)}
                                    </p>
                                    {/* <div className='subd_ethreum'>
                                    <img src={require('../assets/images/faces/Frame.png')} />

                                  </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="row subd_winnercardrow">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                              {/* <div className='subd_ethreum'>
                              <img src={require('../assets/images/faces/Frame.png')} />
                              <p className='subd_value'>340,793</p>
                            </div> */}
                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                              {/* <div className='subd_ethreum'>
                              <img src={require('../assets/images/faces/Frame.png')} />
                              <p className='subd_value'>340,793</p>
                            </div> */}
                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}

                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                              {/* <div className='subd_ethreum'>
                              <img src={require('../assets/images/faces/Frame.png')} />
                              <p className='subd_value'>340,793</p>
                            </div> */}
                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                              {/* <div className='subd_ethreum'>
                              <img src={require('../assets/images/faces/Frame.png')} />
                              <p className='subd_value'>340,793</p>
                            </div> */}
                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                             
                            </div>
                          </div>
                          {/* <i class="fa-solid fa-ellipsis text-white"></i> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                         
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}

                            </div>
                          </div>
                     
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}
                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}

                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="voilet_blurdark"></div>
              <div className="blue_blurdark"></div>
            </div>
          </div>
        </div>
        <SubDomainFooter Item={Sateeee} />
      </div>

      {/* Mobile view */}

      <div
        className="home_header home header_bar_align subdomain_mob"
      >
        <div className="container mobd_customcontainer">
          <div className="allthemes">
            {localStorage.getItem("theme1") == "LT" ||
            localStorage.getItem("theme1") == undefined ? (
              <Button
                onClick={() => {
                  themechange("DT");
                }}
              >
                <p className="light svg_lft_align">
                  <Brightness3 />
                </p>
              </Button>
            ) : (
              <Button
                onClick={() => {
                  themechange("LT");
                }}
              >
                <p className="dark svg_lft_align">
                  <WbSunny />
                </p>
              </Button>
            )}
          </div>
          <div className="listouttss">
            <div className="subd_bannerfull">
              <div className="subd_mobheader">
                <div className="dullblue_blurbg"></div>
                <div className="dullviolet_blurbg"></div>

                {Sateeee?.companylogo == "" ||
                Sateeee?.companylogo == null ||
                Sateeee?.companylogo == undefined ? (
                  <img
                    className="subd_logo"
                    src={require("../assets/images/faces/logo.png")}
                  />
                ) : (
                  <img
                    className="subd_logo"
                    src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                  />
                )}

                {/* <img src={require('../assets/images/faces/hambi.png')} /> */}
              </div>

              <div className="listoutt">
                {Sateeee?.bannerImage == "" ||
                Sateeee?.bannerImage == null ||
                Sateeee?.bannerImage == undefined ? (
                  <ThreeDots
                    height="70"
                    width="70"
                    radius="9"
                    color="#FFC700"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <>
                    <img
                      className="img-fluid subd_mobbanner"
                      src={`${config.Back_URL}/${Sateeee?.bannerImage}`}
                    />

                    {Sateeee?.bannerTittle == "" ||
                    Sateeee?.bannerTittle == null ||
                    Sateeee?.bannerTittle == undefined ? (
                      <h3 className="subd_mobttl">
                        Collect Limited Editions of Halloween NFTs
                      </h3>
                    ) : (
                      <h3 className="subd_mobttl titless">
                        {ReactHTMLParser(Sateeee?.bannerTittle)}
                      </h3>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="subd_mobedition">
              <div className="dullblue_blurbgedit"></div>
              <div className="dullviolet_blurbgedit"></div>
              {Sateeee?.bannerTittle == "" ||
              Sateeee?.bannerTittle == null ||
              Sateeee?.bannerTittle == undefined ? (
                <p className="subd_mobhintt">
                  Immerse yourself in the spooky world of Universal Studio's
                  iconic Halloween events through a collection of exclusive
                  digital collectibles. Each NFT showcases spine-chilling
                  characters, haunted attractions, and memorable moments,
                  meticulously crafted to capture the essence of the Halloween
                  experience. But that's not all - these NFTs also unlock a
                  unique treasure hunt for NFT holders, with a chance to win
                  incredible prizes!
                </p>
              ) : (
                <p className="subd_mobhintt">
                  {ReactHTMLParser(Sateeee.bannerdiscription)}
                </p>
              )}

              <div className="subd_bannerbtns">
                <a href="/#going_toslide">
                  <button className="subd_orangebtn">Collect Now</button>
                </a>

              </div>
            </div>
          </div>
          <div className="subd_mobtrend">
            <div className="dullviolet_blurbgtrend"></div>
            <div className="dullviolet_blurbgtrendleft"></div>
            <div className="dullviolet_blurbgtrendright"></div>
            {Sateeee?.middlebannertittle == "" ||
            Sateeee?.middlebannertittle == null ||
            Sateeee?.middlebannertittle == undefined ? (
              <h5 className="subd_mobttl mt-3">
                Collectible Halloween Tokens Only At Universal Studios in
                Sentosa
              </h5>
            ) : (
              <h5 className="subd_mobttl mt-3">{Sateeee.middlebannertittle}</h5>
            )}
            {Sateeee?.middlebannerdescriptionTittle == "" ||
            Sateeee?.middlebannerdescriptionTittle == null ||
            Sateeee?.middlebannerdescriptionTittle == undefined ? (
              <h5 className="subd_mobttl mt-3">
                Collectible Halloween Tokens Only At Universal Studios in
                Sentosa
              </h5>
            ) : (
              <h5 className="subd_mobttl mt-3">
                {Sateeee.middlebannerdescriptionTittle}
              </h5>
            )}
            {Sateeee?.middlebannerdescription == "" ||
            Sateeee?.middlebannerdescription == null ||
            Sateeee?.middlebannerdescription == undefined ? (
              <p className="subd_jointr_cnthint lefty_left">
                We are a community based collection project featuring 3D and NFT
                artwork. Cool 3D from me and comes in all kinds of evil shape
                traits and sizes with a few surprises along the way
              </p>
            ) : (
              <p className="subd_jointr_cnthint lefty_left">
                {ReactHTMLParser(Sateeee?.middlebannerdescription)}
              </p>
            )}
            <div className="col-12 restof mt-3">
              {Sateeee?.middlebannerimage == "" ||
              Sateeee?.middlebannerimage == null ||
              Sateeee?.middlebannerimage == undefined ? (
              
                <ThreeDots
                  height="70"
                  width="70"
                  radius="9"
                  color="#FFC700"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <img
                  className="img-fluid subd_jointr_contimg"
                  src={`${config.Back_URL}/${Sateeee?.middlebannerimage}`}
                />
              )}
            </div>
            <div className="container subd_nointrcontbg" style={{ backgroundColor: Sateeee.backgroundcolor }}>
              <div className="row">
        
                <div className="col-12">
                  {Sateeee?.middlebannerdescriptionTittle == "" ||
                  Sateeee?.middlebannerdescriptionTittle == null ||
                  Sateeee?.middlebannerdescriptionTittle == undefined ? (
                    <h5 className="subd_jointr_cntttl">
                      Collectible Halloween Tokens Only At Universal Studios in
                      Sentosa
                    </h5>
                  ) : (
                    <h5 className="subd_jointr_cntttl">
                      {Sateeee.middlebannerdescriptionTittle}
                    </h5>
                  )}

                  {Sateeee?.middlebannerdetails == "" ||
                  Sateeee?.middlebannerdetails == null ||
                  Sateeee?.middlebannerdetails == undefined ? (
                    <p className="subd_mobhinttcenter">
                      Exchange for a mystery prize once you had collected all 7
                      NFT tokens
                    </p>
                  ) : (
                    <p className="subd_mobhinttcenter">
                      {ReactHTMLParser(Sateeee.middlebannerdetails)}
                    </p>
                  )}
                  <div className="">
                    <AvatarGroup
                      className="subd_avtgrp"
                      id="scrolling_mobile"
                      max={100}
                    >
                      {Sateeee?.NoOfNfts?.length < 0 ? (
                        <Avatar
                          alt="Remy Sharp"
                          src={require("../assets/images/faces/pf.png")}
                        />
                      ) : (
                        <>
                          {Sateeee?.NoOfNfts?.length > 0 &&
                            Sateeee?.NoOfNfts?.map((item, index) => {
                              {
                                console.log("mapppp", item);
                              }
                              return (
                                <>
                                  {unlocaked?.some(
                                    (it) => it.QR_tokenID == item.QR_tokenID
                                  ) == true ? (
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                    />
                                  ) : (
                
                                    <Avatar
                                      alt="Remy Sharp"
                                      src={require("../assets/images/faces/pf.png")}
                                    />
                                  )}
                                </>
                              );
                            })}
                        </>
                      )}
             
                    </AvatarGroup>

                    {UserDetails !== null ? (
                      <div className="subd_singleavttxt subd_mobsingleavt">
                        <div className="subd_single_avt">
                          {UserDetails?.image == "" ||
                          UserDetails?.image == null ||
                          UserDetails?.image == undefined ? (
                            <>
                              {Sateeee?.companylogo == "" ||
                              Sateeee?.companylogo == null ||
                              Sateeee?.companylogo == undefined ? (
                                <img
                                  src={require("../assets/images/faces/logo.png")}
                                />
                              ) : (
                                <img
                                  src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={`${config.Back_URL}/Users/${UserDetails.PrimaryAddr}/profileImg/${UserDetails?.image}`}
                            />
                          )}
                        
                        </div>
                        <div className="ml-3">
                          <p className="subd_universal">
                            {UserDetails.name == "" ||
                            UserDetails.name == undefined
                              ? localStorage.getItem("ADDR").slice(0, 10)
                              : UserDetails.name}
                          </p>
                  
                        </div>
                      </div>
                    ) : (
                      <div className="subd_singleavttxt subd_mobsingleavt">
                        <div className="subd_single_avt">
                          {Sateeee?.companylogo == "" ||
                          Sateeee?.companylogo == null ||
                          Sateeee?.companylogo == undefined ? (
                            <img
                              src={require("../assets/images/faces/logo.png")}
                            />
                          ) : (
                            <img
                              src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                            />
                          )}
                        </div>
                        <div className="ml-3">
                          {Sateeee?.campaignname == "" ||
                          Sateeee?.campaignname == null ||
                          Sateeee?.campaignname == undefined ? (
                            <p className="subd_universal">Tea Factory</p>
                          ) : (
                            <p className="subd_universal">
                              {Sateeee?.campaignname}
                            </p>
                          )}
                         
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="subd_mobcollectsev">
            {Sateeee?.categoryTittle == "" ||
            Sateeee?.categoryTittle == null ||
            Sateeee?.categoryTittle == undefined ? (
              <h5 className="subd_mobttlhot mt-5">
                Collect ALL 7 of Them and receive one mystery Prize{" "}
              </h5>
            ) : (
              <h5 className="subd_mobttlhot mt-5">{Sateeee.categoryTittle}</h5>
            )}
          </div>

          <Swiper
            style={{ overflow: "hidden" }}
            id="going_toslide"
            pagination={true}
            modules={[Autoplay, Pagination, Navigation]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            slidesPerView={1}
            breakpoints={{
              320: {
                slidesPerView: 1.2,
              },
              576: {
                slidesPerView: 1.2,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            spaceBetween={20}
            className="mySwiper subd_cardswipe mt-5"
          >
            {Sateeee?.NoOfNfts?.length > 0 ? (
              <>
                {Sateeee?.NoOfNfts?.length > 0 &&
                  Sateeee?.NoOfNfts.map((item, index) => {
                    
                    return (
                      <SwiperSlide>
                        <div
                        style={{ backgroundColor: Sateeee.backgroundcolor }}
                          class="card sub_domaincard height_subdomaincard"
                          id={
                            unlocaked?.some(
                              (it) => it.QR_tokenID == item.QR_tokenID
                            ) == true
                              ? ""
                              : "blur_card"
                          }
                        >
                          <div className="blur_effect"></div>
                          {item.image != "" ? (
                            <>
                              {item.image != "" &&
                                (item.image.split(".").pop() == "mp4" ? (
                                  <video
                                    id="my-video"
                                    class="card-img-top subdomain_cardimg"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    preload="auto"
                                    controls
                                    disablePictureInPicture
                                    controlsList="nodownload"
                                  >
                                    <source
                                      src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : (
                                  <img
                                    src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                    alt="Collections"
                                    className="card-img-top subdomain_cardimg"
                                  />
                                ))}
                            </>
                          ) : (
                            <>
                              <img
                                src={require("../assets/images/faces/image2.png")}
                                class="card-img-top subdomain_cardimg"
                                alt=""
                              />
                            </>
                          )}
                          <div class="card-body">
                            <div className="subd_cardwhole">
                              <div>
                                <h6>{item?.tokenName}</h6>
                         
                              </div>
                              {Sateeee?.companylogo == "" ||
                              Sateeee?.companylogo == null ||
                              Sateeee?.companylogo == undefined ? (
                                <img
                                  className="subd_cardsublogo"
                                  src={require("../assets/images/faces/logo.png")}
                                />
                              ) : (
                                <img
                                  className="subd_cardsublogo"
                                  src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                />
                              )}

                            </div>

                            <button className="subd_clctnowbtn">
                              {unlocaked?.some(
                                (it) => it.QR_tokenID == item.QR_tokenID
                              ) == true
                                ? "unlocked"
                                : "locked"}
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </>
            ) : (
              <>
                <ThreeDots
                  height="70"
                  width="70"
                  radius="9"
                  color="#FFC700"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </>
            )}
          </Swiper>

          <div className="subd_mobtopwinner">
            <div className="dullviolet_blurbgtrendwinner"></div>
            <div className="dullblue_blurbgtrendwinner"></div>
            {Sateeee?.TopWinnerTittle == "" ||
            Sateeee?.TopWinnerTittle == null ||
            Sateeee?.TopWinnerTittle == undefined ? (
              <h5 className="subd_mobttlhot mob_topwinner">
                Top Winner this week
              </h5>
            ) : (
              <h5 className="subd_mobttlhot mob_topwinner">
                {Sateeee?.TopWinnerTittle}{" "}
              </h5>
            )}

            {/* <p className='subd_mobhinttcenter mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}

            <Swiper
              style={{ overflow: "hidden" }}
              loop={false}
              pagination={true}
              modules={[Autoplay, Pagination, Navigation]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                576: {
                  slidesPerView: 1,
                },
              }}
              spaceBetween={20}
              className="mySwiper subd_lengthswipe my-5"
            >
              {console.log("ASdasdasdasd", winner)}
              {winner && winner?.length > 0 ? (
                <>
                  {winner && winner?.length > 0 && (
                    <SwiperSlide>
                      {winner && winner?.length > 0 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[0]?.image == "" ||
                                winner[0]?.image == null ||
                                winner[0]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[0]?.PrimaryAddr}/profileImg/${winner[0]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[0]?.name &&
                                      winner[0]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[0]?.PrimaryAddr &&
                                      winner[0]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 1 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[1]?.image == "" ||
                                winner[1]?.image == null ||
                                winner[1]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[1]?.PrimaryAddr}/profileImg/${winner[1]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[1]?.name &&
                                      winner[1]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[1]?.PrimaryAddr &&
                                      winner[1]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 2 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[2]?.image == "" ||
                                winner[2]?.image == null ||
                                winner[2]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[2]?.PrimaryAddr}/profileImg/${winner[2]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[2]?.name &&
                                      winner[2]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[2]?.PrimaryAddr &&
                                      winner[2]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                            
                            </div>
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  )}
                  {winner && winner?.length > 3 && (
                    <SwiperSlide>
                      {winner && winner?.length > 3 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[3]?.image == "" ||
                                winner[3]?.image == null ||
                                winner[3]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[3]?.PrimaryAddr}/profileImg/${winner[3]?.image}`}
                                  />
                                )}

                                {console.log("winner[4]", winner[3])}
                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[3]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[3]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                        
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 4 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[4]?.image == "" ||
                                winner[4]?.image == null ||
                                winner[4]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[4]?.PrimaryAddr}/profileImg/${winner[4]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[4]?.name &&
                                      winner[4]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[4]?.PrimaryAddr &&
                                      winner[4]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 5 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[5]?.image == "" ||
                                winner[5]?.image == null ||
                                winner[5]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[5]?.PrimaryAddr}/profileImg/${winner[5]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[5]?.name &&
                                      winner[5]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[5]?.PrimaryAddr &&
                                      winner[5]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                          
                            </div>
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  )}
                  {winner && winner?.length > 6 && (
                    <SwiperSlide>
                      {winner && winner?.length > 6 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[6]?.image == "" ||
                                winner[6]?.image == null ||
                                winner[6]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[6]?.PrimaryAddr}/profileImg/${winner[6]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[6]?.name &&
                                      winner[6]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[6]?.PrimaryAddr &&
                                      winner[6]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 7 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[7]?.image == "" ||
                                winner[7]?.image == null ||
                                winner[7]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[7]?.PrimaryAddr}/profileImg/${winner[7]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[7]?.name &&
                                      winner[7]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[7]?.PrimaryAddr &&
                                      winner[7]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      )}
                      {winner && winner?.length > 8 && (
                        <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                          <div class="card-body">
                            <div className="subd_winnercard_whole">
                              <div className="d-flex justify-content-start align-items-center">
                                {winner[8]?.image == "" ||
                                winner[8]?.image == null ||
                                winner[8]?.image == undefined ? (
                                  <>
                                    {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={require("../assets/images/faces/logo.png")}
                                      />
                                    ) : (
                                      <img
                                        className="subd_cardsublogoal"
                                        src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <img
                                    className="subd_cardsublogoal"
                                    src={`${config.Back_URL}/Users/${winner[8]?.PrimaryAddr}/profileImg/${winner[8]?.image}`}
                                  />
                                )}

                                <div className="ml-3">
                                  <p className="subd_universal">
                                    {winner[8]?.name !== "" &&
                                      winner[8]?.name.slice(0, 20)}
                                  </p>
                                  <p className="subd_universal">
                                    {winner[8]?.PrimaryAddr &&
                                      winner[8]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  )}
                </>
              ) : (
                <>
                  <SwiperSlide>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                     
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              <p className="subd_universal">Tea Factory</p>
                            </div>
                          </div>
                    
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div class="card subd_winnercard" style={{backgroundColor: Sateeee.backgroundcolor}}>
                      <div class="card-body">
                        <div className="subd_winnercard_whole">
                          <div className="d-flex justify-content-start align-items-center">
                            {Sateeee?.companylogo == "" ||
                            Sateeee?.companylogo == null ||
                            Sateeee?.companylogo == undefined ? (
                              <img
                                className="subd_cardsublogoal"
                                src={require("../assets/images/faces/logo.png")}
                              />
                            ) : (
                              <img
                                className="subd_cardsublogoal"
                                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                              />
                            )}

                            <div className="ml-3">
                              {Sateeee?.campaignname == "" ||
                              Sateeee?.campaignname == null ||
                              Sateeee?.campaignname == undefined ? (
                                <p className="subd_universal">Tea Factory</p>
                              ) : (
                                <p className="subd_universal">
                                  {Sateeee?.campaignname}
                                </p>
                              )}
                            </div>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>
        </div>

        <SubDomainFooter Item={Sateeee} />
      </div>

      {/* End of Mobile view */}
    </>
  );
}

export default NewSubDomain;
