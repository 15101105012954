import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  FormText,
} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

import { BsThreeDotsVertical, BsThreeDots } from "react-icons/bs";
import { IoSearchSharp, IoSunnyOutline, IoMoonOutline } from "react-icons/io5";
import { LuWallet2 } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import "../assets/css/Style.css";

import SubDomainFooter from "components/Footer/SubDomainFooter";

import bannerImg from "../assets/images/Loyalty/bannerimg.png";
import nftImg from "../assets/images/Loyalty/nft.png";
import star from "../assets/images/Loyalty/svg/Star.svg";
import headerLogo from "../assets/images/Loyalty/svg/logo.svg";
import cred from "../assets/images/Loyalty/svg/cred.svg";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import {
  GetDomainsAndDetails,
  TopWinners,
  NftsUnloacked,
  CampaignUsers,
} from "../actions/v1/token";
import ReactHTMLParser from "react-html-parser";
import config from "lib/config";
// import bannerImg from "../assets/images/Loyalty/profile.jpeg";

const PDUdata = [
  {
    id: 1,
    Journey: "Journey 1",
    title: "Learn-To-Earn",
    starRating: 5.0,
    tokenNumber: 2,
    totalToken: "Total Tokens Obtained  = 16 / 16",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 2,
    Journey: "Journey 2",
    title: "Play-To-Earn",
    starRating: 4.9,
    tokenNumber: 4,
    totalToken: "Total Tokens Obtained  = 18 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 3,
    Journey: "Journey 3",
    title: "Refer-To-Earn",
    starRating: 4.9,
    tokenNumber: 6,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 4,
    Journey: "Journey 4",
    title: "Pay-To-Earn",
    starRating: 4.9,
    tokenNumber: 1,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 5,
    Journey: "Journey 5",
    title: "Invest-To-Earn",
    starRating: 4.9,
    tokenNumber: 10,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 6,
    Journey: "Journey 6",
    title: "Donate-To-Earn",
    starRating: 4.9,
    tokenNumber: 11,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 7,
    Journey: "Journey 7",
    title: "Exercise-To-Earn",
    starRating: 4.9,
    tokenNumber: 20,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 8,
    Journey: "Journey 8",
    title: "Save-To-Earn",
    starRating: 4.9,
    tokenNumber: 14,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 9,
    Journey: "Journey 9",
    title: "Travel-To-Earn",
    starRating: 4.9,
    tokenNumber: 16,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },

];

const topAchieverData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
];

const LoyaltyNew = () => {
  console.log("itssss comming on loyalty");
  const cookies = new Cookies();
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  console.log("aasdasdasdas", Wallet_Details);
  const [viewBanner, setViewBanner] = useState(true);
  const [activeCard, setActiveCard] = useState(1);
  const [pduTitle, setPduTitle] = useState("");
  const [PDuDatas, setPDuDatas] = useState([]);
  console.log("PDuDatas", PDuDatas);
  const [cardTitle, setCardTitle] = useState(PDuDatas[0]?.Name);
  const [cardSubtitle, setCardSubtitle] = useState(PDuDatas[0]?.Description);
  const [Sateeee, SetStatewww] = useState("");
  const [theme1, settheme1] = useState(true);
  const [winner, Setwinner] = useState([]);
  const [winnerplayer, SetwinnerPlayer] = useState([]);
  const [unlocaked, SetUnlocked] = useState();
  const [CookieData, SetCookieData] = useState(undefined);
  const [CookieDataAddress, SetCookieDataAddress] = useState(null);
  const [Cookeieeeee, SetCookeieeeee] = useState(null);
  const [UserDetails, SetUserDetails] = useState(null);
  const [journeyNfts, setjourneyNfts] = useState([]);
  const [PointsData, setPointsData] = useState(0);
  const [ClaimedTokenCount, setClaimedTokenCount] = useState(0);
  //const [PDUdata, setPDUdata] = useState([])

  const [tknNumber, setTknNumber] = useState(16);

  const PDUpoints = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
  ];

  let PDUpoints2 = [];

  const addPduToken = () => {
    for (let i = 1; i <= tknNumber; i++) {
      PDUpoints2.push({ id: i });
    }
  };
  addPduToken();

  useEffect(() => {
    setCardSubtitle(PDuDatas[0]?.Description);
    setCardTitle(PDuDatas[0]?.Name);
  }, [PDuDatas]);
  const handleCard = (value, index) => {
    console.log("cardId", value, index);
    setjourneyNfts(
      Sateeee && Sateeee?.Dropdown1Category?.length > 0
        ? Sateeee?.Dropdown1Category[index - 1]
        : null
    );

    setCardTitle(value.Name);
    setCardSubtitle(value.Description);
    setActiveCard(index);

    console.log("tokennumber", value.tokenNumber);
    setTknNumber(value.tokenNumber);
  };
  const handlePduCardClose = (getActiveID) => {
    console.log("getactiveid", getActiveID);
    setActiveCard(null);
  };

  useEffect(() => {
    AOS.init();

    console.log("asdsadfsaasdas", localStorage.getItem("theme1"));
    themechange(localStorage.getItem("theme1"));
    console.log("cardId", cardTitle);
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
    var backdrops = document.getElementsByClassName("modal-backdrop");
    for (var b = 0; b < backdrops.length; b++) {
      backdrops[b].classList.add("d-none");
    }
  }, []);

  const themechange = (data) => {
    console.log("localStorage.getItem", data);
    if (data == "DT" || data == undefined) {
      localStorage.setItem("theme1", "DT");
      document.getElementById("root").classList.remove("light_theme");
      document.getElementById("root").classList.add("dark_theme");

      // this.state.theme === true
      // this.setState({theme:true})
      settheme1(false);
    } else {
      localStorage.setItem("theme1", "LT");
      document.getElementById("root").classList.add("light_theme");
      document.getElementById("root").classList.remove("dark_theme");

      // this.setState({theme:false})
      // this.state.theme === false
      settheme1(true);
    }
  };

  useEffect(
    () => {
      Backendcalll();
      console.log("asdadqwdqwd", cookies.get("TokenIDSCookie"));
      document.getElementsByTagName("body")[0].classList.remove("modal-open");
      TokenTopWinner();
    },
    [cookies.get("TokenIDSCookie")],
    Sateeee
  );

  const Backendcalll = async () => {
    console.log("Loaction For Routes", window.location.hostname);
    const SubDomain = getSubDomain(window.location.hostname);
    console.log("SubDomain", SubDomain);
    if (SubDomain !== "") {
      var sendData = {
        domainname: SubDomain,
      };
      var calllssss = await GetDomainsAndDetails(sendData);
      console.log("jknjckdkaasas", calllssss);
      if (calllssss?.data?.Data == null) {
        console.log("aasdsdadas", Apps);
        // return Apps[0].app
        SetStatewww([]);
      } else {
        console.log("commmingggfdjij");
        //return Apps[1].app
        localStorage.setItem("Event", calllssss?.data?.Data.domainname);
        localStorage.setItem("Creator", calllssss?.data?.Data.curraddress);
        setPDuDatas(calllssss?.data?.Data.Dropdown1Category);
        //PDUdata = calllssss?.data?.Data.Dropdown1Category
        SetStatewww(calllssss?.data?.Data);
        SetPosition(calllssss?.data?.Data?.Dropdown2Category[0]?.Level)
        setjourneyNfts(
          calllssss?.data?.Data &&
            calllssss?.data?.Data?.Dropdown1Category?.length > 0
            ? calllssss?.data?.Data?.Dropdown1Category[0]
            : null
        );
        const treasureNftArray = calllssss?.data?.Data?.Dropdown1Category?.map(item => item.TreasureNft) || [];
  
        // Flatten the array in case TreasureNft is nested
        const flattenedTreasureNft = treasureNftArray.flat();
      console.log("flattenedTreasureNftflattenedTreasureNft",flattenedTreasureNft);
        // Set the flattened array in state
     
        const totalTokensObtained = flattenedTreasureNft?.reduce((total, value) => {
          const matchedCount = unlocaked?.filter(it => it.QR_tokenID === value.QR_tokenID)?.length || 0;
          return total + matchedCount;
        }, 0);
        console.log("totalTokensObtained", totalTokensObtained);
        setClaimedTokenCount(totalTokensObtained);
      }
    } else {
      SetStatewww([]);
    }
  };

  const getSubDomain = (location) => {
    const locationParts = location.split(".");
    console.log("dddwqeqwejqw", locationParts);
    let slicefill = -2;
    const isLocalhost = locationParts.slice(-1)[0] === "localhost";
    if (isLocalhost) {
      slicefill = -1;
    }
    console.log(
      "dajkmfjfa",
      isLocalhost,
      locationParts,
      locationParts.slice(0, slicefill).join("")
    );
    return locationParts.slice(0, slicefill).join("");
  };
console.log("cookies.get",cookies.get("ClaimerCookie"));
  useEffect(() => {
    setTimeout(() => {
    console.log("TokenIDSCookie", cookies.get("TokenIDSCookie"),cookies.get("ClaimerCookie"));
    // if (CookieData) {
    if(cookies.get("ClaimerCookie")){  
    localStorage.setItem("Ids", cookies.get("TokenIDSCookie"));

    localStorage.setItem("ADDR", cookies.get("ClaimerCookie"));
    }

    // localStorage.setItem("ADDR", "0x81088cf2289435ce3cb70fa0a6317b92787dc7d9".toLowerCase())
    ClaimedNfts(
      cookies.get("TokenIDSCookie"),
      cookies.get('ClaimerCookie') || localStorage.getItem("ADDR"),
      Sateeee
    );
    // ClaimedNfts(cookies.get('TokenIDSCookie'),"0x81088cf2289435ce3cb70fa0a6317b92787dc7d9".toLowerCase(),Sateeee)

    // }
    GetConnectedUser(cookies.get('ClaimerCookie'));
  },4000)
  }, [cookies.get('ClaimerCookie')]);

  useEffect(() => {
    console.log("TokenIDSCookie", cookies.get("TokenIDSCookie"),cookies.get("ClaimerCookie"));
    if (cookies.get("ClaimerCookie")) {
    localStorage.setItem("Ids", cookies.get("TokenIDSCookie"));

    localStorage.setItem("ADDR", cookies.get("ClaimerCookie"));
    }
    // localStorage.setItem("ADDR", "0x81088cf2289435ce3cb70fa0a6317b92787dc7d9".toLowerCase())
    ClaimedNfts(
      cookies.get("TokenIDSCookie"),
      cookies.get('ClaimerCookie') || localStorage.getItem("ADDR"),
      Sateeee
    );
    // ClaimedNfts(cookies.get('TokenIDSCookie'),"0x81088cf2289435ce3cb70fa0a6317b92787dc7d9".toLowerCase(),Sateeee)

    // }
    GetConnectedUser(cookies.get('ClaimerCookie'));

  }, [cookies.get('ClaimerCookie')]);

  const [Position, SetPosition] = useState("Silver");

  const TokenTopWinner = async (Addr) => {
    var SendData = {
      EventCreator: localStorage.getItem("Creator"),
      DomainName: localStorage.getItem("Event"),
      Address: localStorage.getItem("ADDR")
      //cookies.get('ClaimerCookie'),
    };
    console.log("asasdasdasd", SendData);
    var Winner = await TopWinners(SendData);
    console.log("Winner", Winner);
    Setwinner(Winner?.data?.TopWinners);
    if (Winner?.data?.TopWinnersPlayer) {
      console.log("e.target for Quantity", Winner?.data?.TopWinnersPlayer);
      let QuantityEntered = Winner?.data?.TopWinnersPlayer[0]?.Points;
      setPointsData(QuantityEntered || 0);
      var Condition = Sateeee.Dropdown2Category;
      console.log("ConditionCondition", Condition);
      for (let i = 0; i < Condition?.length; i++) {
        if (QuantityEntered >= Number(Condition[i].Points)) {
          SetPosition(Condition[i].Level);
        }
      }
    }
  };

  const ClaimedNfts = async (IDS, Addr, Sate) => {
    console.log("Asdasdasdsa", IDS, CookieData, IDS !== CookieData, CookieData !== undefined, Cookeieeeee);
    console.log("asdasdasdqweeq", CookieData, IDS && CookieData !== IDS, IDS && CookieData !== IDS && CookieData !== undefined, Addr);

    if (Addr) {
      console.log("samdklsfcldsm", cookies.get("TokenIDSCookie"));

      console.log(
        "ClaimedNfts",
        CookieData,
        CookieDataAddress,
        IDS,
        Addr,
        localStorage.getItem("Ids")
      );
      var SendData = {
        claimer: localStorage.getItem("ADDR"),
        QR_tokenID: localStorage.getItem("Ids"),
        EventCreator: localStorage.getItem("Creator"),
        DomainName: localStorage.getItem("Event"),
      };
      console.log("SendData", SendData);
      var UnloackedNfts = await NftsUnloacked(SendData);
      console.log("UnloackedNfts", UnloackedNfts);
      SetUnlocked(UnloackedNfts?.data?.Data);
      SetCookeieeeee(IDS);
   
      console.log("oioioioioioi", Sateeee);
    }
  };

  const MatchFun = async (add, unlock) => {
    console.log("asdasdasdasdqweqw", add, unlock, Sateeee);
    var filterData = await Sateeee?.NoOfNfts?.map((item, index) => {
      console.log("Adasaseqweqweqweq", item);
      var matchData = add?.filter((it) => it.QR_tokenID == item.QR_tokenID);
      console.log("matchDataa", matchData);
    });
  };

  const GetConnectedUser = async (Addr) => {
    if (Addr) {
      console.log("samdklsfcldsm", cookies.get("TokenIDSCookie"));

      console.log("Userss", Addr);
      var SendData = {
        claimer: Addr,
        // localStorage.getItem("ADDR"),
      };
      console.log("SendData", SendData);
      var UserDetail = await CampaignUsers(SendData);
      console.log("UserDetail", UserDetail);
      SetUserDetails(UserDetail?.data?.Data);
    }
  };

  return (
  
    <section className="lyt_inr_page" 
    // style={ localStorage.theme1 == "LT" &&  {backgroundColor: Sateeee.backgroundcolor  }}
    >
      <Container className="cust_containers container">
        {/* start of header */}
        {console.log("localStorage.theme1",localStorage.getItem("theme1"))}
        <div className="d-block d-lg-none pt-3 mb-4 text-center">
        {localStorage.getItem("theme1") == "LT" ||
              localStorage.getItem("theme1") == undefined ? (
              <div
                onClick={() => {
                  themechange("DT");
                }}
              >
                <IoSunnyOutline className="lyt_hds_theme" />
              </div>
            ) : (
              <div
                onClick={() => {
                  themechange("LT");
                }}
              >
                <IoSunnyOutline className="lyt_hds_theme" />
              </div>
            )}
        </div>
{console.log("Sateeee", Sateeee)}
            <div className="text-right pt-3 mb-3">
             <a href="https://lazyminter.com/" target="_blank"> <button className="create_btn">Log In</button></a>
              </div>   

        <div className="d-block d-lg-flex align-items-center justify-content-center justify-content-lg-between">
          <div className="lyt_img_wrp mb-3 mb-lg-0 mx-auto mx-lg-0">
            {Sateeee?.companylogo == "" ||
              Sateeee?.companylogo == null ||
              Sateeee?.companylogo == undefined ? (
              <img src={headerLogo} alt="Logo" className="img-fluid" />
            ) : (
              <img
                className="img-fluid"
                src={`${config.Back_URL}/${Sateeee?.companylogo}`}
              />
            )}
          </div>

          <div 
          className="mx-3"
          // className="d-none d-lg-block mx-3"
          >
            {Sateeee?.categoryTittle == "" ||
              Sateeee?.categoryTittle == null ||
              Sateeee?.categoryTittle == undefined ? (
              <h5 className="lyt_hds_tle text-center text-lg-start">AetherOne Loyalty Program</h5>
            ) : (
              <h5 className="lyt_hds_tle text-center text-lg-start">{Sateeee?.categoryTittle}</h5>
            )}

            {Sateeee?.middlebannertittle == "" ||
              Sateeee?.middlebannertittle == null ||
              Sateeee?.middlebannertittle == undefined ? (
              <p className="text-center lyt_hds_subtle lyt_hds_subtles">
                Where Every Point is a Promise Kept
              </p>
            ) : (
              <p className="text-center lyt_hds_subtle lyt_hds_subtles">
                {Sateeee?.middlebannertittle}
              </p>
            )}
          </div>


          <div className="d-lg-flex align-items-lg-center lyt_hds_rgt d-none">
            <IoSearchSharp className="lyt_hds_srch mr-4 d-none" />
            <div className="lyt_crd_img_wrp mr-4 d-none">
              <img src={cred} alt="credentials" className="img_fit_cvr" />
            </div>
            <LuWallet2 className="lyt_hds_wlt mr-4 d-none" />

            <div className="d-none d-lg-block">
            {localStorage.getItem("theme1") == "LT" ||
              localStorage.getItem("theme1") == undefined ? (
              <div
                onClick={() => {
                  themechange("DT");
                }}
              >
                <IoSunnyOutline className="lyt_hds_theme" />
              </div>
            ) : (
              <div
                onClick={() => {
                  themechange("LT");
                }}
              >
                <IoSunnyOutline className="lyt_hds_theme" />
              </div>
            )}
            </div>


            {/* <Button
            onClick={() => {
              themechange("DT");
            }}
          >
            <IoSunnyOutline className="lyt_hds_theme" />
          </Button> */}
          </div>
        </div>

        {/* end of header */}
        {/* start of banner section */}
        {console.log(Sateeee,"sateeeeeee")}

        <div className="lyt_banner my-4  my-xl-5" style={{backgroundColor: Sateeee.backgroundcolor  }}>
          <Row className="d-flex flex-lg-row flex-column-reverse">
            <Col lg={6}>
              <div>
                {Sateeee?.bannerTittle == "" ||
                  Sateeee?.bannerTittle == null ||
                  Sateeee?.bannerTittle == undefined ? (
                  <h3
                    className="text-center lyt_h3 mt-5 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    Unlocking Rewards,
                    <br /> Securing Trust
                  </h3>
                ) : (
                  <h3
                    className="text-center lyt_h3 mt-5 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    {Sateeee?.bannerTittle}
                  </h3>
                )}

                {Sateeee?.bannerdiscription == "" ||
                  Sateeee?.bannerdiscription == null ||
                  Sateeee?.bannerdiscription == undefined ? (
                  <p
                    className="lyt_bnr_p px-3 text-center text-lg-start  mt-4 mt-xl-5"
                  >
                    Join Aether One's Web3 loyalty program and dive into a
                    professional accreditation adventure with our specially
                    curated learning modules. Start by finding the "Knowledge
                    Explorer Token" in your LM wallet, which opens up a unique
                    learning experience. Participate in various journeys to earn
                    your rewards and the satisfaction of a well-earned prize at
                    various stages of the journeys.
                  </p>
                ) : (
                  <p
                    className="lyt_bnr_p px-3 text-center text-lg-start mt-4 mt-xl-5"
                  >
                    {ReactHTMLParser(Sateeee.bannerdiscription)}
                  </p>
                )}
                <div className="text-center text-lg-start">
                <a href="#collectnow">
                <button
                  className="ylw_btn mt-4 ml-2 mb-4 mt-xl-5"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  Collect Now
                </button></a>
                </div>
              </div>
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center mt-5 mt-lg-0"
            >
              <div>
                {viewBanner && (
                  <div className="lyt_bnr_img_wrp">
                    {Sateeee?.bannerImage == "" ||
                      Sateeee?.bannerImage == null ||
                      Sateeee?.bannerImage == undefined ? (
                      // <ThreeDots
                      //   height="70"
                      //   width="70"
                      //   radius="9"
                      //   color="#FFC700"
                      //   ariaLabel="three-dots-loading"
                      //   wrapperStyle={{}}
                      //   wrapperClassName=""
                      //   visible={true}
                      // />
                      <img
                        src={bannerImg}
                        alt="Loyalty"
                        className="img-fluid img_fit_cvr"
                      />
                    ) : (
                      <img
                        alt="Loyalty"
                        className="img-fluid img_fit_cvr"
                        src={`${config.Back_URL}/${Sateeee?.bannerImage}`}
                      />
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>

        {/* end of banner section */}

        {/* start of PDU Points */}

        <div>
          <h5 className="lyt_pdu_pnts_tle mt-5 mb-4">
            {/* 1080 PDU Points | Level <span>Bronze</span> */}
            {PointsData} {Sateeee?.PDUPoints ? Sateeee?.PDUPoints : "PDU"}{" "}
            Points | Level <span>{Position}</span>
          </h5>

          <div className="" id="collectnow">
            <Row>
              <Col lg={6} xl={5} className="col-xxl-4">
                {console.log("PDUdata", PDuDatas)}
                {Sateeee?.Dropdown1Category?.map((value, index) => (
                  <>
                    <div
                    style={{ backgroundColor: Sateeee.backgroundcolor }}
                      className={`lyt_pdu_card  p-3 mb-4 ${activeCard == index + 1 ? "active" : ""
                        }`}
                      onClick={() => handleCard(value, index + 1)}
                    >
                      <Row className="mx-auto">
                        <Col
                          xs={4}
                          sm={4}
                          className="px-0 d-flex align-items-start"
                        >
                          <div className="lyt_pdu_img_wrp">
                            {value?.ImageName ? (
                              <img
                                src={`${config?.Back_URL}/Events/${Sateeee.curraddress}/jorney/${value?.ImageName}`}
                                alt="title"
                                className="img-fluid img_fit_cvr"
                              />
                            ) : (
                              <img
                                src={nftImg}
                                alt="title"
                                className="img-fluid img_fit_cvr"
                              />
                            )}
                          </div>
                        </Col>
                        {/* start of web view */}
                        <Col xs={7} sm={7} className="d-none d-sm-block px-1">
                          <div className="d-flex flex-column">
                            <div>
                              <p className="lyt_pdu_crd_subtle mb-0">
                                {value?.Journey}
                              </p>
                              <p className="lyt_pdu_crd_tle mb-0">
                                {value?.Name}
                              </p>
                            </div>
                            <div>
                              <Row className="mx-auto mt-2">
                                {/* <Col xs={4} className="pl-0  lyt_pdu_star_wrp">
                                  <div className="d-flex align-items-center ">
                                    <img
                                      src={star}
                                      alt="start"
                                      className="img-fluid"
                                    />
                                    <p className="mb-0 ml-1 lyt_pdu_crd_rat">
                                      4.9
                                    </p>
                                  </div>
                                </Col> */}

                                {/* <Col xs={12} className="pr-0 pl-0  lyt_pdu_tkn_dtls ">
                                  <p>
                                    {" "}
                                    Total Tokens Obtained = {
                                      ClaimedTokenCount
                                 
                                    }{" "}
                                    / {value?.TreasureNft?.length}{" "}
                                  </p>
                                </Col> */}
                                <Col xs={12} className="pr-0 pl-0 lyt_pdu_tkn_dtls">
  <p>
    Total Tokens Obtained = {
      value?.TreasureNft?.filter(
        (value) => unlocaked?.some(it => it.QR_tokenID === value.QR_tokenID)
      ).length
    }{" "}
    / {value?.TreasureNft?.length || 0}
  </p>
</Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-none d-sm-flex align-items-sm-center justify-content-sm-end"
                        >
                          <div>
                            <BsThreeDotsVertical className="lyt_pdu_dot_icn" />
                          </div>
                        </Col>
                        {/* end of web view */}

                        {/* start of mobile view */}
                        <Col
                          xs={8}
                          className="d-flex d-sm-none align-items-center pr-0 "
                        >
                          <div>
                            <Row className="d-flex align-items-center mx-auto">
                              <Col xs={10} className="pl-0 ">
                                <div className="d-flex flex-column">
                                  <p className="lyt_pdu_crd_subtle mb-0">
                                    {value.Journey}
                                  </p>
                                  <p className="lyt_pdu_crd_tle mb-0">
                                    {value.Name}
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={2}
                                className="px-0 d-flex justify-content-end"
                              >
                                <div>
                                  <BsThreeDotsVertical className="lyt_pdu_dot_icn" />
                                </div>
                              </Col>
                            </Row>
                            <Row className="mx-auto mt-2">
                              {/* <Col xs={4} className="pl-0  lyt_pdu_star_wrp">
                                <div className="d-flex align-items-center ">
                                  <img
                                    src={star}
                                    alt="start"
                                    className="img-fluid"
                                  />
                                  <p className="mb-0 ml-1 lyt_pdu_crd_rat">
                                    4.9
                                  </p>
                                </div>
                              </Col> */}

                <Col xs={12} className="pr-0 pl-0 lyt_pdu_tkn_dtls">
  <p>
    Total Tokens Obtained = {
      value?.TreasureNft?.filter(
        (value) => unlocaked?.some(it => it.QR_tokenID === value.QR_tokenID)
      ).length
    }{" "}
    / {value?.TreasureNft?.length || 0}
  </p>
</Col>

                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {console.log(
                      "index",
                      index,
                      activeCard,
                      cardTitle,
                      cardSubtitle
                    )}
                    {activeCard == index + 1 && (
                      <div className="pdu_content_wraper p-4 d-lg-none position-relative mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <div className="position-absolute lyt_pdu_crd_cls_icn">
                          <IoMdClose
                            style={{
                              color: "white",
                              fontSize: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePduCardClose(activeCard)}
                          />
                        </div>
                        <h2 className="lyt_h3_two">{cardTitle}</h2>
                        <p className="lyt_pdu_rgt_subtle">{cardSubtitle}</p>
                      </div>
                    )}
                    {/* end of mobile view */}
                  </>
                ))}
              </Col>
              <Col lg={6} xl={7} className="col-xxl-8 pl-xl-5  ">
                <div className="pdu_content_wraper p-4 d-none d-lg-block mb-5" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                  <h2 className="lyt_h3_two">{cardTitle}</h2>
                  <p className="lyt_pdu_rgt_subtle">{cardSubtitle}</p>
                </div>

                {/* start of tabs */}
                <div className="d-none d-lg-block lyt_cust_tabs">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row className="cust_lyt_pdu_pnts">
                      {/* <Col xs={12} className="d-flex justify-content-center">
                        <Nav
                          variant="pills"
                          className="d-flex align-items-center"
                        >
                          <Nav.Item style={{ paddingRight: "11px" }}>
                            Total
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="first">2023</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">2024</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">2025</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="four">2026</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col> */}
                      <Col xs={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            {console.log("journeyNfts?.TreasureNft",journeyNfts?.TreasureNft)}
                            <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                              {journeyNfts?.TreasureNft?.length} Tokens |{" "}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? (Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                )) == NaN ? 0 : Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points
                            </h5>
                            <Row
                              className="lyt_pnts_crds"
                            // data-aos="fade-up"
                            // data-aos-duration="3000"
                            >
                              {journeyNfts &&
                                journeyNfts?.TreasureNft?.length > 0 ? (
                                journeyNfts?.TreasureNft.map(
                                  (value) => (
                                    console.log("vsdfvsdsfvsdf", value),
                                    (
                                      <Col
                                        lg={12}
                                        xl={6}
                                        className="col-xxl-4 col-xxxl-3"
                                      >
                                        <div
                                          style={{ backgroundColor: Sateeee.backgroundcolor }}
                                          class="card sub_domaincard height_subdomaincard"
                                          id={
                                            unlocaked?.some(
                                              (it) =>
                                                it.QR_tokenID ==
                                                value.QR_tokenID
                                            ) == true
                                              ? ""
                                              : "blur_card"
                                          }
                                        >
                                          <div className="blur_effect"></div>
                                          <div className="blur_card_style"></div>
                                          <div className="lyt_pnts_crd_img_wrp">
                                            {value?.image != "" ? (
                                              <>
                                                {value?.image != "" &&
                                                  (value?.image
                                                    .split(".")
                                                    .pop() == "mp4" ? (
                                                    <video
                                                      id="my-video"
                                                      class="img_fit_cvr lyt_pnts_crd_img"
                                                      autoPlay
                                                      playsInline
                                                      loop
                                                      muted
                                                      preload="auto"
                                                      controls
                                                      disablePictureInPicture
                                                      controlsList="nodownload"
                                                    >
                                                      <source
                                                        src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`}
                                                        type="video/mp4"
                                                      />
                                                    </video>
                                                  ) : (
                                                    <img
                                                      src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`}
                                                      alt="Collections"
                                                      className="img_fit_cvr lyt_pnts_crd_img"
                                                    />
                                                  ))}
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </>
                                            )}
                                          </div>

                                          <div class="card-body">
                                            <div className="subd_cardwhole">
                                              <div>
                                                <h6 className="lyt_pdu_h6">
                                                  {value?.tokenName
                                                    // .slice(0, 15)
                                                    // .concat("...")
                                                    }
                                                </h6>
                                              </div>

                                              <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                <p className="mb-0">
                                                  {value?.Points}
                                                </p>
                                              </div>
                                            </div>

                                            {/* <button className="lyt_pnts_crd_btn">
                                              <p className="mb-0">Locked</p>
                                            </button> */}
                                                   <button className='lyt_pnts_crd_btn'>
                                                   <p className="mb-0">{unlocaked?.some(it => it.QR_tokenID == value.QR_tokenID) == true ? "unlocked" : "locked"}</p>
                                                    </button>
                                          </div>
                                        </div>
                                      </Col>
                                    )
                                  )
                                )
                              ) : (
                                <Col
                                  lg={12}
                                  xl={6}
                                  className="col-xxl-4 col-xxxl-3"
                                >
                                  <div
                                    style={{ backgroundColor: Sateeee.backgroundcolor }}
                                    class="card sub_domaincard height_subdomaincard"
                                    id="1"
                                  >
                                    <div className="lyt_pnts_crd_img_wrp">
                                      <img
                                        src={require("../assets/images/faces/image2.png")}
                                        className="img_fit_cvr lyt_pnts_crd_img"
                                        alt=""
                                      />
                                    </div>

                                    <div class="card-body">
                                      <div className="subd_cardwhole">
                                        <div>
                                          <h6 className="lyt_pdu_h6">
                                            Task Name
                                          </h6>
                                        </div>

                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                          <p className="mb-0">26</p>
                                        </div>
                                      </div>

                                      {/* <button className="lyt_pnts_crd_btn">
                                        <p className="mb-0">Locked</p>
                                      </button> */}
                                         <button className='lyt_pnts_crd_btn'>
                                                   <p className="mb-0">{unlocaked?.some(it => it.QR_tokenID == value.QR_tokenID) == true ? "unlocked" : "locked"}</p>
                                                    </button>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Tab.Pane>

                          {/* <Tab.Pane eventKey="first">
                            <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Row
                              className="lyt_pnts_crds"
                              data-aos="fade-up"
                              data-aos-duration="3000"
                            >
                              {PDUpoints2.map((value) => (
                                <Col
                                  lg={12}
                                  xl={6}
                                  className="col-xxl-4 col-xxxl-3"
                                >
                                  <div
                                    class="card sub_domaincard height_subdomaincard"
                                    id="1"
                                  >
                                    <div className="lyt_pnts_crd_img_wrp">
                                      <img
                                        src={require("../assets/images/faces/image2.png")}
                                        className="img_fit_cvr lyt_pnts_crd_img"
                                        alt=""
                                      />
                                    </div>

                                    <div class="card-body">
                                      <div className="subd_cardwhole">
                                        <div>
                                          <h6 className="lyt_pdu_h6">
                                            Task Name
                                          </h6>
                                        </div>

                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                          <p className="mb-0">26</p>
                                        </div>
                                      </div>

                                      <button className="lyt_pnts_crd_btn">
                                        <p className="mb-0">Locked</p>
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Tab.Pane> */}

                          {/* <Tab.Pane eventKey="second">
                            <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Row className="lyt_pnts_crds ">
                              {PDUpoints2.map((value) => (
                                <Col
                                  lg={12}
                                  xl={6}
                                  className="col-xxl-4 col-xxxl-3"
                                >
                                  <div
                                    class="card sub_domaincard height_subdomaincard"
                                    id="1"
                                  >
                                    <div className="lyt_pnts_crd_img_wrp">
                                      <img
                                        src={require("../assets/images/faces/image2.png")}
                                        className="img_fit_cvr lyt_pnts_crd_img"
                                        alt=""
                                      />
                                    </div>

                                    <div class="card-body">
                                      <div className="subd_cardwhole">
                                        <div>
                                          <h6 className="lyt_pdu_h6">
                                            Task Name
                                          </h6>
                                        </div>

                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                          <p className="mb-0">26</p>
                                        </div>
                                      </div>

                                      <button className="lyt_pnts_crd_btn">
                                        <p className="mb-0">Locked</p>
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Row className="lyt_pnts_crds ">
                              {PDUpoints2.map((value) => (
                                <Col
                                  lg={12}
                                  xl={6}
                                  className="col-xxl-4 col-xxxl-3"
                                >
                                  <div
                                    class="card sub_domaincard height_subdomaincard"
                                    id="1"
                                  >
                                    <div className="lyt_pnts_crd_img_wrp">
                                      <img
                                        src={require("../assets/images/faces/image2.png")}
                                        className="img_fit_cvr lyt_pnts_crd_img"
                                        alt=""
                                      />
                                    </div>

                                    <div class="card-body">
                                      <div className="subd_cardwhole">
                                        <div>
                                          <h6 className="lyt_pdu_h6">
                                            Task Name
                                          </h6>
                                        </div>

                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                          <p className="mb-0">26</p>
                                        </div>
                                      </div>

                                      <button className="lyt_pnts_crd_btn">
                                        <p className="mb-0">Locked</p>
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="four">
                            <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Row className="lyt_pnts_crds ">
                              {PDUpoints2.map((value) => (
                                <Col
                                  lg={12}
                                  xl={6}
                                  className="col-xxl-4 col-xxxl-3"
                                >
                                  <div
                                    class="card sub_domaincard height_subdomaincard"
                                    id="1"
                                  >
                                    <div className="lyt_pnts_crd_img_wrp">
                                      <img
                                        src={require("../assets/images/faces/image2.png")}
                                        className="img_fit_cvr lyt_pnts_crd_img"
                                        alt=""
                                      />
                                    </div>

                                    <div class="card-body">
                                      <div className="subd_cardwhole">
                                        <div>
                                          <h6 className="lyt_pdu_h6">
                                            Task Name
                                          </h6>
                                        </div>

                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                          <p className="mb-0">26</p>
                                        </div>
                                      </div>

                                      <button className="lyt_pnts_crd_btn">
                                        <p className="mb-0">Locked</p>
                                      </button>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Tab.Pane> */}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
                <div className="lyt_pnts_crds lyt_cust_tabs d-lg-none">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      {/* <Col xs={12} className="d-flex justify-content-center">
                        <Nav
                          variant="pills"
                          className="d-flex align-items-center"
                        >
                          <Nav.Item style={{ paddingRight: "11px" }}>
                            Total
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="first">2023</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">2024</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">2025</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="four">2026</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col> */}
                      <Col xs={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <h5 className="text-center lyt_pdu_crd_hds my-4">
                              {journeyNfts?.TreasureNft?.length} Tokens |{" "}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points{" "}
                            </h5>
                            <Swiper
                              style={{ overflow: "hidden" }}
                              id="going_toslide"
                              pagination={true}
                              modules={[Autoplay, Pagination, Navigation]}
                              autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                              }}
                              slidesPerView={1}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1.2,
                                },
                                576: {
                                  slidesPerView: 2,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                992: {
                                  slidesPerView: 3,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                              }}
                              spaceBetween={20}
                              className="mySwiper subd_cardswipe mt-5"
                            >
                              {journeyNfts &&
                                journeyNfts?.TreasureNft?.length > 0 ? (
                                <>
                                  {journeyNfts?.TreasureNft.map((value) => {
                                    return (
                                      <SwiperSlide>
                                        <div
                                        style={{ backgroundColor: Sateeee.backgroundcolor }}
                                          class="card sub_domaincard height_subdomaincard"
                                          id={
                                            unlocaked?.some(
                                              (it) =>
                                                it.QR_tokenID ==
                                                value.QR_tokenID
                                            ) == true
                                              ? ""
                                              : "blur_card"
                                          }
                                        >
                                          <div className="blur_effect"></div>
                                          <div className="blur_card_style"></div>
                                          <div className="lyt_pnts_crd_img_wrp">
                                            {/* <img
                                              src={require("../assets/images/faces/image2.png")}
                                              className="img_fit_cvr lyt_pnts_crd_img"
                                              alt=""
                                            /> */}
                                            {value?.image != "" ? (
                                              <>
                                                {value?.image != "" &&
                                                  (value?.image
                                                    .split(".")
                                                    .pop() == "mp4" ? (
                                                    <video
                                                      id="my-video"
                                                      class="img_fit_cvr lyt_pnts_crd_img"
                                                      autoPlay
                                                      playsInline
                                                      loop
                                                      muted
                                                      preload="auto"
                                                      controls
                                                      disablePictureInPicture
                                                      controlsList="nodownload"
                                                    >
                                                      <source
                                                        src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`}
                                                        type="video/mp4"
                                                      />
                                                    </video>
                                                  ) : (
                                                    <img
                                                      src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`}
                                                      alt="Collections"
                                                      className="img_fit_cvr lyt_pnts_crd_img"
                                                    />
                                                  ))}
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </>
                                            )}
                                          </div>

                                          <div class="card-body">
                                            <div className="subd_cardwhole">
                                              <div>
                                                <h6>
                                                  {value?.tokenName
                                                    // .slice(0, 15)
                                                    // .concat("...")
                                                    }
                                                </h6>
                                              </div>

                                              <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                <p className="mb-0">
                                                  {value?.Points}
                                                </p>
                                              </div>
                                            </div>

                                            {/* <button className="lyt_pnts_crd_btn">
                                              <p className="mb-0">Locked</p>
                                            </button> */}
                                               <button className='lyt_pnts_crd_btn'>
                                                   <p className="mb-0">{unlocaked?.some(it => it.QR_tokenID == value.QR_tokenID) == true ? "unlocked" : "locked"}</p>
                                                    </button>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <ThreeDots
                                    height="70"
                                    width="70"
                                    radius="9"
                                    color="#FFC700"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </>
                              )}
                            </Swiper>
                          </Tab.Pane>

                          {/* <Tab.Pane eventKey="second">
                            <h5 className="text-center my-4 lyt_pdu_crd_hds ">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Swiper
                              style={{ overflow: "hidden" }}
                              id="going_toslide"
                              pagination={true}
                              modules={[Autoplay, Pagination, Navigation]}
                              autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                              }}
                              slidesPerView={1}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1.2,
                                },
                                576: {
                                  slidesPerView: 2,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                992: {
                                  slidesPerView: 3,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                              }}
                              spaceBetween={20}
                              className="mySwiper subd_cardswipe mt-5"
                            >
                              {PDUpoints2?.length > 0 ? (
                                <>
                                  {PDUpoints2.map((value) => {
                                    return (
                                      <SwiperSlide>
                                        <div
                                          class="card sub_domaincard height_subdomaincard"
                                          id="1"
                                        >
                                          <div className="lyt_pnts_crd_img_wrp">
                                            <img
                                              src={require("../assets/images/faces/image2.png")}
                                              className="img_fit_cvr lyt_pnts_crd_img"
                                              alt=""
                                            />
                                          </div>

                                          <div class="card-body">
                                            <div className="subd_cardwhole">
                                              <div>
                                                <h6>Task Name</h6>
                                              </div>

                                              <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                <p className="mb-0">26</p>
                                              </div>
                                            </div>

                                            <button className="lyt_pnts_crd_btn">
                                              <p className="mb-0">Locked</p>
                                            </button>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <ThreeDots
                                    height="70"
                                    width="70"
                                    radius="9"
                                    color="#FFC700"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </>
                              )}
                            </Swiper>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <h5 className="text-center  lyt_pdu_crd_hds my-4">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Swiper
                              style={{ overflow: "hidden" }}
                              id="going_toslide"
                              pagination={true}
                              modules={[Autoplay, Pagination, Navigation]}
                              autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                              }}
                              slidesPerView={1}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1.2,
                                },
                                576: {
                                  slidesPerView: 2,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                992: {
                                  slidesPerView: 3,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                              }}
                              spaceBetween={20}
                              className="mySwiper subd_cardswipe mt-5"
                            >
                              {PDUpoints2?.length > 0 ? (
                                <>
                                  {PDUpoints2.map((value) => {
                                    return (
                                      <SwiperSlide>
                                        <div
                                          class="card sub_domaincard height_subdomaincard"
                                          id="1"
                                        >
                                          <div className="lyt_pnts_crd_img_wrp">
                                            <img
                                              src={require("../assets/images/faces/image2.png")}
                                              className="img_fit_cvr lyt_pnts_crd_img"
                                              alt=""
                                            />
                                          </div>

                                          <div class="card-body">
                                            <div className="subd_cardwhole">
                                              <div>
                                                <h6>Task Name</h6>
                                              </div>

                                              <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                <p className="mb-0">26</p>
                                              </div>
                                            </div>

                                            <button className="lyt_pnts_crd_btn">
                                              <p className="mb-0">Locked</p>
                                            </button>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <ThreeDots
                                    height="70"
                                    width="70"
                                    radius="9"
                                    color="#FFC700"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </>
                              )}
                            </Swiper>
                          </Tab.Pane>
                          <Tab.Pane eventKey="four">
                            <h5 className="text-center my-4 lyt_pdu_crd_hds">
                              16 Tokens | 320 PDU Points
                            </h5>
                            <Swiper
                              style={{ overflow: "hidden" }}
                              id="going_toslide"
                              pagination={true}
                              modules={[Autoplay, Pagination, Navigation]}
                              autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                              }}
                              slidesPerView={1}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1.2,
                                },
                                576: {
                                  slidesPerView: 2,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                992: {
                                  slidesPerView: 3,
                                },
                                1200: {
                                  slidesPerView: 4,
                                },
                              }}
                              spaceBetween={20}
                              className="mySwiper subd_cardswipe mt-5"
                            >
                              {PDUpoints2?.length > 0 ? (
                                <>
                                  {PDUpoints2.map((value) => {
                                    return (
                                      <SwiperSlide>
                                        <div
                                          class="card sub_domaincard height_subdomaincard"
                                          id="1"
                                        >
                                          <div className="lyt_pnts_crd_img_wrp">
                                            <img
                                              src={require("../assets/images/faces/image2.png")}
                                              className="img_fit_cvr lyt_pnts_crd_img"
                                              alt=""
                                            />
                                          </div>

                                          <div class="card-body">
                                            <div className="subd_cardwhole">
                                              <div>
                                                <h6>Task Name</h6>
                                              </div>

                                              <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                <p className="mb-0">26</p>
                                              </div>
                                            </div>

                                            <button className="lyt_pnts_crd_btn">
                                              <p className="mb-0">Locked</p>
                                            </button>
                                          </div>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <ThreeDots
                                    height="70"
                                    width="70"
                                    radius="9"
                                    color="#FFC700"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                  />
                                </>
                              )}
                            </Swiper>
                          </Tab.Pane> */}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* end of PDU Points */}

        {/* start of top achiever */}

        <div className="my-4 my-lg-5">
          {Sateeee?.TopWinnerTittle == "" ||
            Sateeee?.TopWinnerTittle == null ||
            Sateeee?.TopWinnerTittle == undefined ? (
            <h1 className=" text-center lyt_ach_hds">Top Achiever This Week</h1>
          ) : (
            <h4 className=" text-center lyt_ach_hds">
              {Sateeee?.TopWinnerTittle}{" "}
            </h4>
          )}
          {/* start of mobile top achiever card  */}
          {console.log("sdfdfdafa", winner)}
          {/* {winner && winner?.length > 0 ? ( */}
          {winner && winner !== null && winner?.length > 0 ? (
            <div
              className="d-md-none"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <Swiper
                style={{ overflow: "hidden" }}
                loop={false}
                pagination={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                slidesPerView={1}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={20}
                className="mySwiper subd_lengthswipe my-4"
              >
                {winner && winner !== null && winner?.length > 0 ? (
                  <>
                    {winner && winner?.length > 0 && (
                      <SwiperSlide>
                        {winner && winner?.length > 0 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[0]?.image == "" ||
                                    winner[0]?.image == null ||
                                    winner[0]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[0]?.PrimaryAddr}/profileImg/${winner[0]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[0]?.name &&
                                      winner[0]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[0]?.PrimaryAddr &&
                                      winner[0]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[0].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[0].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              {/* <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  <BsThreeDots className="lyt_ach_dot_icn" />
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 1 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[1]?.image == "" ||
                                    winner[1]?.image == null ||
                                    winner[1]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[1]?.PrimaryAddr}/profileImg/${winner[1]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[1]?.name &&
                                      winner[1]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[1]?.PrimaryAddr &&
                                      winner[1]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[1].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[1].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              {/* <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  <BsThreeDots className="lyt_ach_dot_icn" />
                                </div>
                              </Col> */}
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 2 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[2]?.image == "" ||
                                    winner[2]?.image == null ||
                                    winner[2]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[2]?.PrimaryAddr}/profileImg/${winner[2]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[2]?.name &&
                                      winner[2]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[2]?.PrimaryAddr &&
                                      winner[2]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[2].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[2].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </SwiperSlide>
                    )}
                    {winner && winner?.length > 3 && (
                      <SwiperSlide>
                        {winner && winner?.length > 3 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[3]?.image == "" ||
                                    winner[3]?.image == null ||
                                    winner[3]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[3]?.PrimaryAddr}/profileImg/${winner[3]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[3]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[3]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[3].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[3].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 4 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[4]?.image == "" ||
                                    winner[4]?.image == null ||
                                    winner[4]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[4]?.PrimaryAddr}/profileImg/${winner[4]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[4]?.name &&
                                      winner[4]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[4]?.PrimaryAddr &&
                                      winner[4]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[4].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[4].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 5 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[5]?.image == "" ||
                                    winner[5]?.image == null ||
                                    winner[5]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[5]?.PrimaryAddr}/profileImg/${winner[5]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                <p className="lyt_ach_crd_p_two mb-0">
                                  Wallet Address
                                </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[5]?.name &&
                                      winner[5]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[5]?.PrimaryAddr &&
                                      winner[5]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[5].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[5].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </SwiperSlide>
                    )}
                    {winner && winner?.length > 6 && (
                      <SwiperSlide>
                        {winner && winner?.length > 6 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[6]?.image == "" ||
                                    winner[6]?.image == null ||
                                    winner[6]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[6]?.PrimaryAddr}/profileImg/${winner[6]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[6]?.name &&
                                      winner[6]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[6]?.PrimaryAddr &&
                                      winner[6]?.PrimaryAddr?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[6].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[6].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 7 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[7]?.image == "" ||
                                    winner[7]?.image == null ||
                                    winner[7]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[7]?.PrimaryAddr}/profileImg/${winner[7]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[7]?.name &&
                                      winner[7]?.name?.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[7]?.PrimaryAddr &&
                                      winner[7]?.PrimaryAddr?.slice(0, 20)}
                                  </p>

                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[7].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[7].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {winner && winner?.length > 8 && (
                          <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                            <Row className="mx-auto">
                              <Col
                                xs={3}
                                sm={2}
                                xl={2}
                                className="px-0 d-flex align-items-center"
                              >
                                <div className="lyt_ach_crd_img_wrp">
                                  {winner[8]?.image == "" ||
                                    winner[8]?.image == null ||
                                    winner[8]?.image == undefined ? (
                                    <>
                                      {Sateeee?.companylogo == "" ||
                                        Sateeee?.companylogo == null ||
                                        Sateeee?.companylogo == undefined ? (
                                        <img
                                          src={nftImg}
                                          alt=""
                                          className="img_fit_cvr lyt_ach_crd_img"
                                        />
                                      ) : (
                                        <img
                                          className="img_fit_cvr lyt_ach_crd_img"
                                          src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/Users/${winner[8]?.PrimaryAddr}/profileImg/${winner[8]?.image}`}
                                    />
                                  )}
                                </div>
                              </Col>
                              <Col
                                xs={8}
                                xl={9}
                                className="d-flex align-items-center"
                              >
                                <div className="px-xl-2">
                                  {/* <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                  <p className="lyt_ach_crd_p_two mb-0">
                                    Wallet Address
                                  </p> */}
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[8]?.name !== "" &&
                                      winner[8]?.name.slice(0, 20)}
                                  </p>
                                  <p className="lyt_ach_crd_p_one mb-0">
                                    {winner[8]?.PrimaryAddr &&
                                      winner[8]?.PrimaryAddr?.slice(0, 20)}
                                  </p>

                                  <p className="lyt_ach_crd_p_three mb-0">
                                    {/* 9900 PDU Points | 560 Tokens */}
                                    {winner[8].Points} {Sateeee.PDUPoints}{" "}
                                    Points | {winner[8].count} Tokens
                                  </p>
                                </div>
                              </Col>
                              <Col
                                xs={1}
                                className="px-0 d-flex align-items-center justify-content-end"
                              >
                                <div>
                                  {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </SwiperSlide>
                    )}
                  </>
                ) : (
                  <>
                    <SwiperSlide>
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>{" "}
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>{" "}
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>{" "}
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>{" "}
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            xl={2}
                            className="px-0 d-flex align-items-center"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              <img
                                src={nftImg}
                                alt=""
                                className="img_fit_cvr lyt_ach_crd_img"
                              />
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            xl={9}
                            className="d-flex align-items-center"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                              <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            className="px-0 d-flex align-items-center justify-content-end"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </SwiperSlide>
                  </>
                )}
              </Swiper>
            </div>
          ) : (
            <div
              className="d-md-none"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <Swiper
                style={{ overflow: "hidden" }}
                loop={false}
                pagination={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                slidesPerView={1}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 1,
                  },
                }}
                spaceBetween={20}
                className="mySwiper subd_lengthswipe my-4"
              >
                <>
                  <SwiperSlide>
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          xl={2}
                          className="px-0 d-flex align-items-center"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          xl={9}
                          className="d-flex align-items-center"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          className="px-0 d-flex align-items-center justify-content-end"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </SwiperSlide>
                </>
              </Swiper>
            </div>
          )}
          {/* end of mobile top achiever card  */}

          {/* start of web top achiever card  */}
          {winner && winner !== null && winner?.length > 0 ? (
            <div className="d-none d-md-block my-4 my-lg-5">
              <Row data-aos="fade-up" data-aos-duration="3000">
                {winner &&
                  winner?.length > 0 &&
                  winner.map((Data) => (
                    <Col md={6} xl={6} className="col-xxl-4">
                      <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                        <Row className="mx-auto">
                          <Col
                            xs={3}
                            sm={2}
                            md={3}
                            xl={3}
                            className="px-0 d-flex align-items-center col-xxl-2 col-xxxl-3"
                          >
                            <div className="lyt_ach_crd_img_wrp">
                              {Data?.image == "" ||
                                Data?.image == null ||
                                Data?.image == undefined ? (
                                <>
                                  {console.log("dcdscdcadscas", Data)}

                                  {Sateeee?.companylogo == "" ||
                                    Sateeee?.companylogo == null ||
                                    Sateeee?.companylogo == undefined ? (
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  ) : (
                                    <img
                                      className="img_fit_cvr lyt_ach_crd_img"
                                      src={`${config.Back_URL}/${Sateeee?.companylogo}`}
                                    />
                                  )}
                                </>
                              ) : (
                                <img
                                  className="img_fit_cvr lyt_ach_crd_img"
                                  src={`${config.Back_URL}/Users/${Data?.PrimaryAddr}/profileImg/${Data?.image}`}
                                />
                              )}
                            </div>
                          </Col>
                          <Col
                            xs={8}
                            sm={8}
                            md={8}
                            xl={8}
                            className="d-flex align-items-center col-xxl-9 col-xxxl-8"
                          >
                            <div className="px-xl-2">
                              <p className="lyt_ach_crd_p_one mb-0">
                                {" "}
                                {Data?.name == ""
                                  ? Data.PrimaryAddr?.slice(0, 20)
                                  : Data?.name?.slice(0, 20)}
                              </p>
                              {/* <p className="lyt_ach_crd_p_two mb-0">
                                Wallet Address
                              </p> */}

                              <p className="lyt_ach_crd_p_three mb-0">
                                {Data.Points} {Sateeee.PDUPoints} Points |{" "}
                                {Data.count} Tokens
                              </p>
                            </div>
                          </Col>
                          <Col
                            xs={1}
                            sm={2}
                            md={1}
                            xl={1}
                            className="px-0 d-flex align-items-center justify-content-end col-xxl-1 col-xxxl-1"
                          >
                            <div>
                              {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
          ) : (
            <div className="d-none d-md-block my-4 my-lg-5">
              <Row data-aos="fade-up" data-aos-duration="3000">
                {topAchieverData.map((value) => (
                  <Col md={6} xl={6} className="col-xxl-4">
                    <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Sateeee.backgroundcolor }}>
                      <Row className="mx-auto">
                        <Col
                          xs={3}
                          sm={2}
                          md={3}
                          xl={3}
                          className="px-0 d-flex align-items-center col-xxl-2 col-xxxl-3"
                        >
                          <div className="lyt_ach_crd_img_wrp">
                            <img
                              src={nftImg}
                              alt=""
                              className="img_fit_cvr lyt_ach_crd_img"
                            />
                          </div>
                        </Col>
                        <Col
                          xs={8}
                          sm={8}
                          md={8}
                          xl={8}
                          className="d-flex align-items-center col-xxl-9 col-xxxl-8"
                        >
                          <div className="px-xl-2">
                            <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                            <p className="lyt_ach_crd_p_two mb-0">
                            {Sateeee?.curraddress?.slice(0,15)}
                            </p>
                            <p className="lyt_ach_crd_p_three mb-0">
                              {/* 9900 PDU Points | 560 Tokens */}
                              {journeyNfts?.TreasureNft &&
                                journeyNfts?.TreasureNft?.length > 0
                                ? Number(
                                  journeyNfts?.TreasureNft.reduce((sum, item) => sum + Number(item.Points), 0)
                                ) + " "
                                : 0}
                              {" " + Sateeee?.PDUPoints
                                ? Sateeee?.PDUPoints
                                : "PDU"}{" "}
                              Points {" "}  | {journeyNfts?.TreasureNft?.length} Tokens 
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={1}
                          sm={2}
                          md={1}
                          xl={1}
                          className="px-0 d-flex align-items-center justify-content-end col-xxl-1 col-xxxl-1"
                        >
                          <div>
                            {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {/* end of web top achiever card  */}
        </div>
        {/* end of top achiever */}

        {/* start of footer */}
        <SubDomainFooter Item={Sateeee} />
        {/* end of footer */}
      </Container>
    </section>
  );
};

export default LoyaltyNew;
