import { ContactsOutlined, Pause } from '@material-ui/icons';
import * as V1Toke from 'actions/v1/token';
import * as V1Fract from 'actions/v1/Fractional';
import { encodedata } from 'hooks/useWallet';

/**
 * Retrieves an IPFS image hash based on the provided payload and image.
 * 
 * @async
 * @function ipfsImageHashGet
 * @param {Object} payload - The data for the IPFS image hash request.
 * @param {File} [Image] - The image file to upload to IPFS.
 * @param {string} [address] - The address of the creator.
 * @param {string} [payload.name] - The name associated with the IPFS image.
 * @param {string} [payload.description] - The description of the IPFS image.
 * @param {string} [payload.NFTCategory] - The NFT category for the image.
 * @param {string} [payload.royalties] - The royalties for the NFT.
 * @param {string} [payload.properties] - The properties of the NFT.
 * @param {string} [payload.mail] - The email address associated with the request.
 * @param {string} [payload.ContactNumber] - The contact number associated with the request.
 * @param {Object} [payload.CharityDetails] - The charity details in JSON format.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const ipfsImageHashGet = async (payload, Image, address) => {
  console.log("adasdasdasdasdas create ipfs payload", payload);
  var formData = new FormData();
  if (Image) { formData.append("Image", Image); }
  if (payload.name) { formData.append("name", encodedata(payload.name)); }
  if (payload.description) { formData.append("desc", encodedata(payload.description)); }
  if (address) { formData.append("Creator", encodedata(address)); }
  if (payload.NFTCategory) { formData.append("NFTCategory", encodedata(payload.NFTCategory)); }
  if (payload.royalties) { formData.appen
    /*************  ✨ Codeium Command ⭐  *************/d("royalties", encodedata(payload.royalties)); }
  if (payload.properties) { formData.append("properties", encodedata(payload.properties)); }
  if (payload.mail) { formData.append('mail', encodedata(payload.mail)) }
  if (payload.ContactNumber) { formData.append('ContactNumber', encodedata(payload.ContactNumber)) }
  if (payload.CharityDetails) { formData.append('CharityDetails', encodedata(JSON.stringify(payload.CharityDetails))) }
  var Resp = await V1Toke.v1_ipfsImageHashGet(formData)
  return Resp
}

/**
 * Uploads NFT details to the database, including metadata, images, and additional
 * properties associated with the NFT.
 * 
 * @async
 * @function NFTDetails2DB
 * @param {Object} payload - The data containing NFT details.
 * @param {File} [Image] - The image file associated with the NFT.
 * @param {string} [User] - The address of the user who is the owner and creator.
 * @param {string} [RandomName] - A random name for identification purposes.
 * @param {string} [NonceHash] - The nonce hash for verification.
 * @param {string} [SignatureHash] - The signature hash for verification.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const NFTDetails2DB = async (payload, Image, User, RandomName, NonceHash, SignatureHash) => {
  console.log("nftdb name ,add name", payload)
  var formData = new FormData();
  if (Image) { formData.append('Image', Image); }
  if (payload.IpfsImage) { formData.append('ipfsimage', encodedata(payload.IpfsImage)); }
  if (payload.Ipfs) { formData.append('ipfsmeta', encodedata(payload.Ipfs)); }
  if (payload.metajson) { formData.append('metajson', encodedata(payload.metajson)); }
  if (payload.name) { formData.append('Name', encodedata(payload.name)); }
  if ((payload.TokenID)) { formData.append('TokenID', encodedata(payload.TokenID)); }
  if (payload.description) { formData.append('Description', encodedata(payload.description)); }
  if (payload.TokenPrice) { formData.append('Price', encodedata(payload.TokenPrice)); }
  if (payload.royalties) { formData.append('Royalities', encodedata(payload.royalties)); }
  if (payload.NFTCategory) { formData.append('Category_label', encodedata(payload.NFTCategory)); }
  if (payload.properties) { formData.append('Properties', encodedata(payload.properties)); }
  if (User) { formData.append('Owner', encodedata(User)); }
  if (User) { formData.append('Creator', encodedata(User)) }
  if (payload.quantity) { formData.append('Quantity', encodedata(payload.quantity)) }
  if (payload.quantity) { formData.append('Balance', encodedata(payload.quantity)) }
  if (payload.contractaddress) { formData.append('ContractAddress', encodedata(payload.contractaddress)) }
  if (payload.Status) { formData.append('Status', encodedata(payload.Status)) }
  if (payload.HashValue) { formData.append('HashValue', encodedata(payload.HashValue)) }
  if (payload.type) { formData.append('Type', encodedata(payload.type)) }
  if (payload.MinimumBid) { formData.append('MinimumBid', encodedata(payload.MinimumBid)) }
  if (payload.AuctionEnd) { formData.append('auctionend', encodedata(payload.AuctionEnd)) }
  if (payload.AuctionStart) { formData.append('auctionstart', encodedata(payload.AuctionStart)) }
  if (payload.UnlockContent) { formData.append('UnLockcontent', encodedata(payload.UnlockContent)) }
  if (payload.TokenGating) { formData.append('TokenGating', encodedata(payload.TokenGating)) }
  if (payload.Coinname) { formData.append('coinname', encodedata(payload.Coinname)) }
  if (payload.PutonSale) { formData.append('PutOnSale', encodedata(payload.PutonSale)) }
  if (payload.SaleType) { formData.append('PutOnSaleType', encodedata(payload.SaleType)) }
  if (payload.BuyType) { formData.append('BuyType', encodedata(payload.BuyType)) }
  if (payload.ImageName) { formData.append('image', encodedata(payload.ImageName)) }
  if (payload.additionalImage) { formData.append('additionalImage', encodedata(payload.additionalImage)) }
  if (payload.Date) { formData.append('Date', encodedata(payload.Date)) }
  if (payload.Location) { formData.append('Location', encodedata(payload.Location)) }
  if (payload.Section) { formData.append('Section', encodedata(payload.Section)) }
  if (payload.NetworkName) { formData.append('NetworkName', encodedata(payload.NetworkName)) }
  if (RandomName) { formData.append('RandomName', encodedata(RandomName)) }
  if (NonceHash) { formData.append('NonceHash', encodedata(NonceHash)) }
  if (SignatureHash) { formData.append('SignatureHash', encodedata(SignatureHash)) }

  if (payload.Charity) { formData.append('Charity', encodedata(payload.Charity)) }
  if (payload.CharityDetails) { formData.append('CharityDetails', encodedata(JSON.stringify(payload.CharityDetails))) }
  if (payload.Fiat) { formData.append('Fiat', encodedata(payload.Fiat)) }

  if (payload.Breed) { formData.append('Breed', encodedata(payload.Breed)) }
  if (payload.weight) { formData.append('weight', encodedata(payload.weight)) }
  if (payload.chipno) { formData.append('chipno', encodedata(payload.chipno)) }
  if (payload.height) { formData.append('height', encodedata(payload.height)) }
  if (payload.genders) { formData.append('genders', encodedata(payload.genders)) }
  if (payload.vaccine) { formData.append('vaccine', encodedata(payload.vaccine)) }
  if (payload.mail) { formData.append('mail', encodedata(payload.mail)) }
  if (payload.ContactNumber) { formData.append('ContactNumber', encodedata(payload.ContactNumber)) }
  if (payload.ID) { formData.append('ID', encodedata(payload.ID)) }
  if (payload.DateChoose) { formData.append('DateChoose', encodedata(payload.DateChoose)) }
  if (payload.Terms) { formData.append('Terms', encodedata(payload.Terms)) }
  if (payload.Privacy) { formData.append('Privacy', encodedata(payload.Privacy)) }
  var Resp = await V1Toke.v1_NFTDetails2DB(formData)
  return Resp
}

/**
 * Retrieves a user's items data.
 * 
 * @async
 * @function MyItemsData
 * @param {Object} Payload - The data to query with.
 * @returns {Object} - A promise that resolves to the server's response data.
 * @prop {boolean} Success - Whether the query was successful.
 * @prop {string} Tab - The tab that was queried.
 * @prop {Array} records - The records returned by the query.
 */
export const MyItemsData = async (Payload) => {
  var Resp = await V1Toke.v1_MyItemsData(Payload)
  Resp = { Success: Resp.data.Resp.Success, Tab: Resp.data.Tab, records: Resp.data.Resp.records }
  // console.log("hbekbhfrjwkbhkwb",Resp)
  return Resp
}

/**
 * Retrieves NFT information based on the provided payload.
 *
 * This function queries the NFT information using the given payload. If the 
 * payload's location path is not "collection", it calls `v1_NFTInfo` to fetch
 * the details, otherwise, it calls `v1_NFTInfo1`. The function processes the 
 * response to extract the NFT information and filters bids related to the 
 * current address. It returns the first record from the response or an empty 
 * NFTOwners array if no records are found.
 *
 * @async
 * @function NFTInfo
 * @param {Object} Payload - The payload containing query parameters.
 * @param {string} Payload.locationpath - The path to determine the collection.
 * @param {string} Payload.curAddr - The current address for filtering bids.
 * @returns {Object|null} - The NFT information or null.
 */
export const NFTInfo = async (Payload) => {
  // console.log("djgfjdfjdbvjdfhb",Payload);
  if (Payload.locationpath != "collection") {
    // console.log("dkjsdlzzzzzzzkvjsdlk",Payload);
    var Resp = await V1Toke.v1_NFTInfo(Payload)
    var Info = Resp.data
    var NFTInfo = null
    // console.log("NFTInfodetails",Resp.data,Payload)
    if (Info.Success) {
      if (Info.records.length > 0) {
        NFTInfo = Info.records[0]
        NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
      }
      else {
        NFTInfo = { NFTOwners: [] }
      }
    }
    return NFTInfo
  }
  else {
    // console.log("sdfjdkjklsjks",Payload);
    var Resp = await V1Toke.v1_NFTInfo1(Payload)
    var Info = Resp.data
    var NFTInfo = null
    // console.log("NFTInfodetails",Resp.data,Payload)
    if (Info.Success) {
      if (Info.records.length > 0) {
        NFTInfo = Info.records[0]
        NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
      }
      else {
        NFTInfo = { NFTOwners: [] }
      }
    }
    return NFTInfo
  }
}

/**
 * Updates the ownership details of an NFT.
 * 
 * This asynchronous function processes the provided payload to update NFT ownership information.
 * It constructs a payload object containing purchase details, including token price, owner, creator,
 * and various other attributes necessary for the transaction. Depending on whether a token ID is
 * present in the update data, it either updates the token ID or processes the ownership update.
 *
 * @async
 * @function UpdateNFTOwners
 * @param {Object} Payload - The payload containing update information.
 * @param {Object} Payload.NFTDetail - Details of the NFT being updated.
 * @param {string} Payload.Owns - The quantity owned.
 * @param {string} Payload.ConAddr - The contract address of the NFT.
 * @param {string} Payload.NewOwner - The new owner's address.
 * @param {string} Payload.OldOwner - The previous owner's address.
 * @param {string} Payload.Status - The status of the transaction.
 * @param {string} Payload.Hash - The hash value of the transaction.
 * @param {string} Payload.LazyStatus - The lazy minting status.
 * @param {string} Payload.SelectedNetwork - The network selected for the transaction.
 * @param {string} Payload.BulkMint - Bulk minting details.
 * @param {string} Payload.Bulkmint - Additional bulk minting details.
 * @param {string} Payload.tokenName - The name of the token.
 * @param {string} Payload.Collection - The collection to which the NFT belongs.
 * @param {string} Payload.QR_tokenID - The QR token identifier.
 * @param {Object} Payload.updateData - The data to update.
 * @param {Object} Payload.ServiceFee - The service fee details.
 * @param {Object} Payload.FiatBuy - Fiat purchase details.
 * @param {Object} Payload.NFTPrice - The price of the NFT.
 * @param {Array} Payload.Calculation - Calculation details for the transaction.
 * @param {Object} Payload.NFTPriceWithSerice - NFT price with service fee.
 * @param {Object} Payload.HashDb - Database hash details.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const UpdateNFTOwners = async (Payload) => {
  console.log("jkhkhkjhkjhh", Payload);
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Resale"
    }, Action: Payload.Action, updateData: Payload.updateData, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTDetails: Payload.NFTDetail, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.HashDb
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  if (Payload.updateData.tokenID) {
    // alert("corretc")
    console.log("dnvdskjnvjdkfnvdkf", Payload.updateData.tokenID);
    var Resp = await V1Toke.updateTokenID(Payload)
  }
  else {
    // alert("wrong")
    console.log("not comming here",);
    var Resp = await V1Toke.v1_UpdateNFTOwners(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

/**
 * @function UpdateNFTOwnersBulk
 * @description Bulk update NFT owners
 * @param {Object} Payload - Payload with the following properties:
 *  - `Purchase`: Object with the following properties:
 *    - `tokenPrice`: Number
 *    - `coinname`: String
 *    - `deleted`: Number
 *    - `balance`: Number
 *    - `quantity`: Number
 *    - `contractAddress`: String
 *    - `type`: String
 *    - `burnToken`: Number
 *    - `previousPrice`: Number
 *    - `tokenID`: String
 *    - `tokenOwner`: String
 *    - `tokenCreator`: String
 *    - `status`: String
 *    - `PutOnSale`: String
 *    - `hashValue`: String
 *    - `LazyStatus`: String
 *    - `SelectedNetwork`: String
 *    - `BulkMint`: String
 *    - `Bulkmint`: String
 *    - `OldOwner`: String
 *    - `tokenName`: String
 *    - `Collection`: String
 *    - `QR_tokenID`: String
 *    - `GasAmount`: Number
 *    - `FaitStatus`: String
 *    - `Charity`: String
 *    - `SaleStatus`: String
 *  - `Action`: String
 *  - `updateData`: Object
 *  - `NFTDetailsOwner`: Object
 *  - `NFTDetails`: Object
 *  - `ServiceFee`: Object
 *  - `FiatBuy`: Object
 *  - `NFTPrice`: Number
 *  - `Calculation`: Array
 *  - `NFTPriceWithSerice`: Number
 *  - `Hash`: String
 *  - `Points`: Number
 * @returns {Object} - Response from the server with the updated NFT information
 */
export const UpdateNFTOwnersBulk = async (Payload) => {
  console.log("UpdateNFTOwnersBulk", Payload);
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload?.NFTDetail?.type,
      "burnToken": 0,
      "previousPrice": Payload?.NFTDetail?.tokenPrice,
      "tokenID": Payload?.NFTDetail?.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload?.NFTDetail?.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "GasAmount": Payload.GasAmount,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Create"
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.Hash, Points: Payload.Pointss
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  // if(Payload.updateData.tokenID){
  //   // alert("corretc")
  //   // console.log("dnvdskjnvjdkfnvdkf",Payload.updateData.tokenID);
  //   var Resp = await V1Toke.updateTokenIDBulk(Payload)
  // }
  // else
  if (Payload.updateData.tokenID) {
    // alert("wrong")
    // console.log("not comming here",);
    var Resp = await V1Toke.updateTokenIDBulk(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

/**
 * @function UpdateNFTOwnersBulkOwner
 * @description Bulk update NFT owners
 * @param {Object} Payload - Payload with the following properties:
 *  - `Purchase`: Object with the following properties:
 *    - `tokenPrice`: Number
 *    - `coinname`: String
 *    - `deleted`: Number
 *    - `balance`: Number
 *    - `quantity`: Number
 *    - `contractAddress`: String
 *    - `type`: String
 *    - `burnToken`: Number
 *    - `previousPrice`: Number
 *    - `tokenID`: String
 *    - `tokenOwner`: String
 *    - `tokenCreator`: String
 *    - `status`: String
 *    - `PutOnSale`: String
 *    - `hashValue`: String
 *    - `LazyStatus`: String
 *    - `SelectedNetwork`: String
 *    - `BulkMint`: String
 *    - `Bulkmint`: String
 *    - `OldOwner`: String
 *    - `tokenName`: String
 *    - `Collection`: String
 *    - `QR_tokenID`: String
 *    - `GasAmount`: Number
 *    - `FaitStatus`: String
 *    - `Charity`: String
 *    - `SaleStatus`: String
 *  - `Action`: String
 *  - `updateData`: Object
 *  - `NFTDetailsOwner`: Object
 *  - `NFTDetails`: Object
 *  - `ServiceFee`: Object
 *  - `FiatBuy`: Object
 *  - `NFTPrice`: Number
 *  - `Calculation`: Array
 *  - `NFTPriceWithSerice`: Number
 *  - `Hash`: String
 * @returns {Object} - Response from the server with the updated NFT information
 */
export const UpdateNFTOwnersBulkOwner = async (Payload) => {
  
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload?.NFTDetail?.type,
      "burnToken": 0,
      "previousPrice": Payload?.NFTDetail?.tokenPrice,
      "tokenID": Payload?.NFTDetail?.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload?.NFTDetail?.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "GasAmount": Payload.GasAmount,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Create"
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.Hash
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  // if(Payload.updateData.tokenID){
  //   // alert("corretc")
  //   // console.log("dnvdskjnvjdkfnvdkf",Payload.updateData.tokenID);
  //   var Resp = await V1Toke.updateTokenIDBulk(Payload)
  // }
  // else
  if (Payload.updateData.tokenID) {
    // alert("wrong")
    // console.log("not comming here",);
    var Resp = await V1Toke.updateTokenIDBulkOwner(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}


/**
 * Handles the transfer of NFT ownership and generation of token images.
 *
 * This asynchronous function processes the provided payload to update NFT ownership information.
 * It constructs a payload object containing purchase details, including token price, owner, creator,
 * and various other attributes necessary for the transaction. Depending on whether a token ID is
 * present in the update data, it either updates the token ID or generates token images.
 *
 * @async
 * @function imageImger
 * @param {Object} Payload - The payload containing update information.
 * @param {Object} Payload.NFTDetail - Details of the NFT being updated.
 * @param {string} Payload.Owns - The quantity owned.
 * @param {string} Payload.ConAddr - The contract address of the NFT.
 * @param {string} Payload.NewOwner - The new owner's address.
 * @param {string} Payload.OldOwner - The previous owner's address.
 * @param {string} Payload.Status - The status of the transaction.
 * @param {string} Payload.Hash - The hash value of the transaction.
 * @param {string} Payload.LazyStatus - The lazy minting status.
 * @param {string} Payload.SelectedNetwork - The network selected for the transaction.
 * @param {string} Payload.BulkMint - Bulk minting details.
 * @param {string} Payload.Bulkmint - Additional bulk minting details.
 * @param {string} Payload.tokenName - The name of the token.
 * @param {string} Payload.Collection - The collection to which the NFT belongs.
 * @param {string} Payload.QR_tokenID - The QR token identifier.
 * @param {string} Payload.ScannerTokenID - The scanner token identifier.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const imageImger = async (Payload) => {
  
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "ScannerTokenID": Payload.ScannerTokenID
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens
  }

  
  if (Payload.updateData.tokenID) {
 
    var Resp = await V1Toke.BulkImgGenNext(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

/**
 * Handles the bulk transfer of NFT ownership and verification.
 *
 * This asynchronous function processes the provided payload to update NFT ownership information
 * in bulk. It constructs a payload object containing purchase details, including token price, owner,
 * creator, and various other attributes necessary for the transaction. Depending on whether a token ID is
 * present in the update data, it verifies and processes the admin transfer.
 *
 * @async
 * @function imageImgerBulkTransfer
 * @param {Object} Payload - The payload containing update information.
 * @param {Object} Payload.NFTDetail - Details of the NFT being updated.
 * @param {string} Payload.Owns - The quantity owned.
 * @param {string} Payload.ConAddr - The contract address of the NFT.
 * @param {string} Payload.NewOwner - The new owner's address.
 * @param {string} Payload.OldOwner - The previous owner's address.
 * @param {string} Payload.Status - The status of the transaction.
 * @param {string} Payload.Hash - The hash value of the transaction.
 * @param {string} Payload.LazyStatus - The lazy minting status.
 * @param {string} Payload.SelectedNetwork - The network selected for the transaction.
 * @param {string} Payload.BulkMint - Bulk minting details.
 * @param {string} Payload.Bulkmint - Additional bulk minting details.
 * @param {string} Payload.tokenName - The name of the token.
 * @param {string} Payload.Collection - The collection to which the NFT belongs.
 * @param {string} Payload.QR_tokenID - The QR token identifier.
 * @param {string} Payload.ScannerTokenID - The scanner token identifier.
 * @param {Object} Payload.updateData - The data to update.
 * @param {Object} Payload.NFTDetailsOwner - The owner details of the NFT.
 * @param {Object} Payload.NFTTokens - Tokens related to the NFT.
 * @param {File} Payload.ExcelFile - The Excel file related to the transaction.
 * @param {number} Payload.Balance - The balance of the transaction.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const imageImgerBulkTransfer = async (Payload) => {
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "ScannerTokenID": Payload.ScannerTokenID
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ExcelFile: Payload.ExcelFile, Balance: Payload.Balance
  }

  if (Payload.updateData.tokenID) {
    console.log("asdasdsadasdds", Payload.updateData.tokenID);
    var Resp = await V1Toke.VerifyAndAdminTransfer(Payload)
  }
  return Resp.data
}

/**
 * Processes a bid action and updates the database with bid details.
 *
 * This asynchronous function takes a payload containing bid information,
 * constructs a payload object with the necessary details, and sends it
 * to the server for processing. It updates the bid status, token quantity,
 * and other relevant bid attributes in the database.
 *
 * @async
 * @function BidAction2DB
 * @param {Object} Payload - The payload containing bid information.
 * @param {string} Payload.Quantity - The quantity of tokens being bid on.
 * @param {number} Payload.BidAmt - The amount being bid for the tokens.
 * @param {string} Payload.Bidder - The address of the bidder.
 * @param {string} Payload.tokenID - The identifier of the token being bid on.
 * @param {string} Payload.coinName - The name of the cryptocurrency used for bidding.
 * @param {string} Payload.QR_tokenID - The QR token identifier.
 * @param {string} Payload.tokeName - The name of the token.
 * @param {string} Payload.Hash - The hash value for the transaction.
 * @param {string} Payload.ConAddr - The contract address of the token.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const BidAction2DB = async (Payload) => {
  var Payload = {
    Payload: {
      "status": "pending",
      "deleted": 1,
      "NoOfToken": Payload.Quantity,
      "completed": 0,
      "cancelled": 0,
      "pending": Payload.Quantity,
      "tokenBidAmt": Payload.BidAmt,
      "tokenBidAddress": Payload.Bidder,
      "tokenID": Payload.tokenID,
      "coinname": Payload.coinName,
      "QR_tokenID": Payload.QR_tokenID
    },
    Act: {
      "tokeName": Payload.tokeName,
      "hash": Payload.Hash,
      "ConAddr": Payload.ConAddr,
      "Coin": Payload.coinName
    }
  }
  var Resp = await V1Toke.v1_BidAction2DB(Payload)
  
  return Resp.data
}

/**
 * Retrieves a list of NFTs for a given event.
 *
 * This asynchronous function takes a payload containing the event details and
 * sends it to the server for processing. It returns a promise that resolves to
 * an object containing the server's response data.
 *
 * @async
 * @function EventListNft
 * @param {Object} Payload - Payload with the following properties:
 *  - `curraddress`: The user's current wallet address.
 *  - `Tab`: The tab to query. One of 'activity', 'item', or 'favorite'.
 *  - `Action`: The action to query. Only applicable if `Tab` is 'activity'.
 *  - `TokenID`: The token ID to query. Only applicable if `Tab` is 'item'.
 *  - `Limit`: The maximum number of records to return.
 *  - `Page`: The page number to return.
 *  - `Filter`: The filter to apply to the records. Only applicable if `Tab` is 'activity'.
 * @returns {Object} - A promise that resolves to the server's response data.
 *  - `Success`: Whether the query was successful.
 *  - `Tab`: The tab that was queried.
 *  - `records`: The records returned by the query.
 */
export const EventListNft = async (Payload) => {
  var Resp = await V1Toke.v1_EventListNft(Payload)
  Resp = { Success: Resp.data.Resp.Success, Tab: Resp.data.Tab, records: Resp.data.Resp.records }
  return Resp
}

/*************  ✨ Codeium Command ⭐  *************/
/**
 * Retrieves an IPFS image hash based on the provided payload and image.
 *
 * This asynchronous function takes a payload object and an image file, and
 * returns a promise that resolves to the server's response data.
 *
 * @async
 * @function FractionalIPFS
 * @param {Object} payload - Payload with the following properties:
 *  - `name`: The name of the NFT.
 *  - `description`: The description of the NFT.
 *  - `NFTCategory`: The NFT category.
 *  - `royalties`: The royalties for the NFT.
 *  - `properties`: The properties of the NFT.
 * @param {File} Image - The image file to upload to IPFS.
 * @param {string} address - The address of the creator.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const FractionalIPFS = async (payload, Image, address) => {
  var formData = new FormData();
  if (Image) { formData.append("Image", Image); }
  if (payload.name) { formData.append("name", encodedata(payload.name)); }
  if (payload.description) { formData.append("desc", encodedata(payload.description)); }
  if (address) { formData.append("Creator", encodedata(address)); }
  if (payload.NFTCategory) { formData.append("NFTCategory", encodedata(payload.NFTCategory)); }
  if (payload.royalties) { formData.append("royalties", encodedata(payload.royalties)); }
  if (payload.properties) { formData.append("properties", encodedata(payload.properties)); }
  var Resp = await V1Fract.v1_ipfsImageHashGet(formData)
  return Resp
}

/**
 * Uploads fractional NFT details to the database, including metadata, images, and additional
 * properties associated with the NFT.
 *
 * @async
 * @function Fractional2DB
 * @param {Object} payload - The data containing fractional NFT details.
 * @param {File} [Image] - The image file associated with the fractional NFT.
 * @param {string} [User] - The address of the user who is the owner and creator.
 * @param {string} [RandomName] - A random name for identification purposes.
 * @param {string} [NonceHash] - The nonce hash for verification.
 * @param {string} [SignatureHash] - The signature hash for verification.
 * @returns {Object} - A promise that resolves to the server's response data.
 */
export const Fractional2DB = async (payload, Image, User, RandomName, NonceHash, SignatureHash) => {
  var formData = new FormData();
  if (Image) { formData.append('Image', Image); }
  if (payload.IpfsImage) { formData.append('ipfsimage', encodedata(payload.IpfsImage)); }
  if (payload.Ipfs) { formData.append('ipfsmeta', encodedata(payload.Ipfs)); }
  if (payload.metajson) { formData.append('metajson', encodedata(payload.metajson)); }
  if (payload.name) { formData.append('Name', encodedata(payload.name)); }
  if ((payload.TokenID)) { formData.append('TokenID', encodedata(payload.TokenID)); }
  if (payload.description) { formData.append('Description', encodedata(payload.description)); }
  if (payload.TokenPrice) { formData.append('Price', encodedata(payload.TokenPrice)); }
  if (payload.royalties) { formData.append('Royalities', encodedata(payload.royalties)); }
  if (payload.NFTCategory) { formData.append('Category_label', encodedata(payload.NFTCategory)); }
  if (payload.properties) { formData.append('Properties', encodedata(payload.properties)); }
  if (User) { formData.append('Owner', encodedata(User)); }
  if (User) { formData.append('Creator', encodedata(User)) }
  if (payload.quantity) { formData.append('Quantity', encodedata(payload.quantity)) }
  if (payload.quantity) { formData.append('Balance', encodedata(payload.quantity)) }
  if (payload.contractaddress) { formData.append('ContractAddress', encodedata(payload.contractaddress)) }
  if (payload.Status) { formData.append('Status', encodedata(payload.Status)) }
  if (payload.HashValue) { formData.append('HashValue', encodedata(payload.HashValue)) }
  if (payload.type) { formData.append('Type', encodedata(payload.type)) }
  if (payload.MinimumBid) { formData.append('MinimumBid', encodedata(payload.MinimumBid)) }
  if (payload.AuctionEnd) { formData.append('auctionend', encodedata(payload.AuctionEnd)) }
  if (payload.AuctionStart) { formData.append('auctionstart', encodedata(payload.AuctionStart)) }
  if (payload.UnlockContent) { formData.append('UnLockcontent', encodedata(payload.UnlockContent)) }
  if (payload.TokenGating) { formData.append('TokenGating', encodedata(payload.TokenGating)) }
  if (payload.Coinname) { formData.append('coinname', encodedata(payload.Coinname)) }
  if (payload.PutonSale) { formData.append('PutOnSale', encodedata(payload.PutonSale)) }
  if (payload.SaleType) { formData.append('PutOnSaleType', encodedata(payload.SaleType)) }
  if (payload.BuyType) { formData.append('BuyType', encodedata(payload.BuyType)) }
  if (payload.ImageName) { formData.append('image', encodedata(payload.ImageName)) }
  if (payload.additionalImage) { formData.append('additionalImage', encodedata(payload.additionalImage)) }
  if (payload.Date) { formData.append('Date', encodedata(payload.Date)) }
  if (payload.Location) { formData.append('Location', encodedata(payload.Location)) }
  if (payload.Section) { formData.append('Section', encodedata(payload.Section)) }
  if (payload.NetworkName) { formData.append('NetworkName', encodedata(payload.NetworkName)) }
  if (RandomName) { formData.append('RandomName', encodedata(RandomName)) }
  if (NonceHash) { formData.append('NonceHash', encodedata(NonceHash)) }
  if (SignatureHash) { formData.append('SignatureHash', encodedata(SignatureHash)) }
  if (payload.ERC20) { formData.append('ERC20', encodedata(payload.ERC20)) }
  if (payload.ERCTrade) { formData.append('ERCTrade', encodedata(payload.ERCTrade)) }
  if (payload.Fiat) { formData.append('Fiat', encodedata(payload.Fiat)) }
  var Resp = await V1Fract.v1_NFTDetails2DB(formData)
  return Resp
}

/**
 * Retrieves NFT information based on the provided payload.
 * 
 * This asynchronous function queries the NFT information using the given payload.
 * It processes the response to extract the NFT information and filters bids related
 * to the current address. It returns the first record from the response or an empty
 * NFTOwners array if no records are found.
 *
 * @async
 * @function FractionalNFTInfo
 * @param {Object} Payload - The payload containing query parameters.
 * @param {string} Payload.locationpath - The path to determine the collection.
 * @param {string} Payload.curAddr - The current address for filtering bids.
 * @returns {Object|null} - The NFT information or null.
 */
export const FractionalNFTInfo = async (Payload) => {
  var Resp = await V1Fract.v1_NFTInfo(Payload)
  var Info = Resp.data
  var NFTInfo = null
  if (Info.Success) {
    if (Info.records.length > 0) {
      NFTInfo = Info.records[0]
      NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
    }
    else {
      NFTInfo = { NFTOwners: [] }
    }
  }
  return NFTInfo
}

/**
 * Updates the fractional ownership details of an NFT.
 * 
 * This asynchronous function processes the provided payload to update fractional
 * ownership information. It constructs a payload object containing purchase details,
 * including token price, owner, creator, and various other attributes necessary for
 * the transaction. Depending on whether a token ID is present in the update data,
 * it either updates the token ID or processes the ownership update.
 *
 * @async
 * @function UpdateNFTFractions
 * @param {Object} Payload - The payload containing update information.
 * @param {Object} Payload.NFTDetail - Details of the NFT being updated.
 * @param {string} Payload.Owns - The quantity owned.
 * @param {string} Payload.ConAddr - The contract address of the NFT.
 * @param {string} Payload.NewOwner - The new owner's address.
 * @param {string} Payload.OldOwner - The previous owner's address.
 * @param {string} Payload.Status - The status of the transaction.
 * @param {string} Payload.Hash - The hash value of the transaction.
 * @param {string} Payload.LazyStatus - The lazy minting status.
 * @param {string} Payload.SelectedNetwork - The network selected for the transaction.
 * @param {string} Payload.BulkMint - Bulk minting details.
 * @param {string} Payload.Bulkmint - Additional bulk minting details.
 * @param {string} Payload.tokenName - The name of the token.
 * @param {string} Payload.Collection - The collection to which the NFT belongs.
 * @param {string} Payload.QR_tokenID - The QR token identifier.
 * @param {Object} Payload.ServiceFee - The service fee details.
 * @param {Object} Payload.FiatBuy - Fiat purchase details.
 * @param {Object} Payload.NFTDetails - NFT details.
 * @param {number} Payload.NFTPrice - The price of the NFT.
 * @param {Array} Payload.Calculation - Calculation details for the transaction.
 * @param {Object} Payload.NFTPriceWithSerice - NFT price with service fee.
 * @param {Object} Payload.HashDb - Database hash details.
 * @returns {Object} - A promise that resolves to the server's response data.
 */

export const UpdateNFTFractions = async (Payload) => {
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Resale",
      "ERC20_Address": Payload.ERC20_Address,
      "ERCTrade": Payload.ERC20_Address
    }, Action: Payload.Action, updateData: Payload.updateData, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTDetails: Payload.NFTDetail, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.HashDb
  }


  var Resp = await V1Fract.v1_UpdateNFTOwners(Payload)

  return Resp.data
}
